import { useState } from 'react';
import { DetalleHabilidad } from '../types/DetalleHabilidad';
import clienteApi from '../util/clienteApi';
import { getAuth } from 'firebase/auth';

export const useDetalleHabilidad = () => {
	const [agregandoDetalleHabilidad, setAgregandoDetalleHabilidad] =
		useState<boolean>(false);
	const [cargandoDetalleHabilidad, setCargandoDetalleHabilidad] =
		useState<boolean>(false);
	const [eliminandoDetalleHabilidad, setEliminandoDetalleHabilidad] =
		useState<boolean>(false);
	const [actualizandoDetalleHabilidad, setActualizandoDetalleHabilidad] =
		useState<boolean>(false);
	const [errorDetalleHabilidad, setErrorDetalleHabilidad] =
		useState<boolean>(false);
	const [mensajeDeError, setMensajeDeError] = useState<string>('');
	const { currentUser } = getAuth();

	const agregarDetalleHabilidad = async (
		detalleHabilidad: DetalleHabilidad,
	) => {
		try {
			setAgregandoDetalleHabilidad(true);
			const datosDeLaConsulta = {
				detalleHabilidad,
				idUsuario: currentUser?.uid,
			};
			await clienteApi.put('/detalleHabilidades', datosDeLaConsulta);
			setAgregandoDetalleHabilidad(false);
		} catch (e: any) {
			setErrorDetalleHabilidad(true);
			setMensajeDeError(e.response.data);
			setAgregandoDetalleHabilidad(false);
		}
	};

	const actualizarDetalleHabilidad = async (
		detalleHabilidad: DetalleHabilidad,
	) => {
		try {
			setActualizandoDetalleHabilidad(true);
			const datosDeLaConsulta = {
				detalleHabilidad,
				idUsuario: currentUser?.uid,
			};
			await clienteApi.patch('/detalleHabilidades', datosDeLaConsulta);
			setActualizandoDetalleHabilidad(false);
		} catch (e: any) {
			setErrorDetalleHabilidad(true);
			setMensajeDeError(e.response.data);
			setAgregandoDetalleHabilidad(false);
		}
	};

	const eliminarDetalleHabilidad = async (
		idDetalleHabilidadAEliminar: string,
	) => {
		try {
			setEliminandoDetalleHabilidad(true);
			const idDeUsuario: string | null | undefined = currentUser?.uid;
			await clienteApi.delete(
				`/detalleHabilidades/${idDetalleHabilidadAEliminar}/${idDeUsuario}`,
			);
			setEliminandoDetalleHabilidad(false);
		} catch (e) {
			setErrorDetalleHabilidad(true);
		}
	};

	const listarDetalleHabilidadPorIdDeUsuario = async (): Promise<
		DetalleHabilidad[]
	> => {
		setCargandoDetalleHabilidad(true);
		let detalleHabilidadDelUsuario: DetalleHabilidad[] = [];
		try {
			const idDeUsuario: string | null | undefined = currentUser?.uid;
			const response = await clienteApi.get(
				`/detalleHabilidades/${idDeUsuario}`,
			);
			detalleHabilidadDelUsuario = response.data;
		} catch (e: any) {
			setErrorDetalleHabilidad(true);
		} finally {
			setCargandoDetalleHabilidad(false);
			return detalleHabilidadDelUsuario;
		}
	};

	return {
		agregarDetalleHabilidad,
		listarDetalleHabilidadPorIdDeUsuario,
		setErrorDetalleHabilidad,
		eliminarDetalleHabilidad,
		actualizarDetalleHabilidad,
		actualizandoDetalleHabilidad,
		eliminandoDetalleHabilidad,
		agregandoDetalleHabilidad,
		cargandoDetalleHabilidad,
		errorDetalleHabilidad,
		mensajeDeError,
	};
};
