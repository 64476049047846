import { faCircleInfo } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import styled from 'styled-components';
import InfoHabilidad from './InfoHabilidad';
import { Colores } from '../constantes/style';

interface TooltipProps {
	textoPopUp?: string;
	icono?: string;
	todo?: boolean;
	textoInfoHabilidad?: string;
	interes?: string;
	nivel?: string;
}
const Tooltip = ({
	textoPopUp,
	textoInfoHabilidad = '',
	interes,
	nivel,
}: TooltipProps) => {
	const [positionX, setPositionX] = useState<number>(0);
	const [positionY, setPositionY] = useState<number>(0);
	return (
		<Contenedor
			onMouseMove={event => {
				setPositionX(event.clientX);
				setPositionY(event.clientY);
			}}
		>
			{esUnComponenteInfoHabilidad(textoInfoHabilidad, interes, nivel) ? (
				<InfoHabilidad
					texto={textoInfoHabilidad}
					interes={interes}
					nivel={nivel}
				/>
			) : (
				<IconoTooltip>
					<FontAwesomeIcon
						icon={faCircleInfo}
						color={Colores.VIOLETA}
					/>
				</IconoTooltip>
			)}

			<PopUp
				positionX={positionX - 5}
				positionY={positionY - 45}
			>
				{GeneradorDeTextoPopUp(
					textoInfoHabilidad,
					interes,
					nivel,
					textoPopUp,
				)}
			</PopUp>
		</Contenedor>
	);
};

const esUnComponenteInfoHabilidad = (
	texto?: string,
	interes?: string,
	nivel?: string,
) => {
	return Boolean(texto && interes && nivel);
};
const GeneradorDeTextoPopUp = (
	textoInfoHabilidad?: string,
	interes?: string,
	nivel?: string,
	textoPopUp?: string,
) => {
	if (esUnComponenteInfoHabilidad(textoInfoHabilidad, interes, nivel)) {
		const interesUpperCase = interes ? interes.toUpperCase() : '';
		const nivelUpperCase = nivel ? nivel.toUpperCase() : '';
		return (
			<>
				<div>{`Nivel de interés:  ${interesUpperCase}`}</div>
				<div>{`Nivel de conocimiento: ${nivelUpperCase}`}</div>
			</>
		);
	} else {
		return <>{textoPopUp}</>;
	}
};

const PopUp = styled.span<{
	positionX: number;
	positionY: number;
}>`
	visibility: hidden;
	width: 200px;
	background-color: white;
	color: ${Colores.VIOLETA};
	text-align: center;
	font-size: 13px;
	border-radius: 6px;
	border: solid 1px ${Colores.VIOLETA};
	padding: 5px;
	box-shadow: 1px 1px 14px ${Colores.VIOLETA};
	position: fixed;
	z-index: 1;
	${props => ` left: ${props.positionX}px; top: ${props.positionY}px`};
`;
const IconoTooltip = styled.span`
	border: none;
	cursor: pointer;
	color: ${Colores.VIOLETA};
	height: 10px;
	width: 10px;
`;

const Contenedor = styled.span`
	&:hover ${PopUp} {
		visibility: visible;
		pointer-events: none;
	}
`;
export default Tooltip;
