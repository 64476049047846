import Contenedor from '../components/Contenedor';
import Titulo from '../components/Titulo';
import styled from 'styled-components';
import { Colores, Grilla } from '../constantes/style';
import ContenedorDeHabilidadesMasDeseadas from '../components/ContenedorDeHabilidadesMasDeseadas';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown, faArrowUp } from '@fortawesome/free-solid-svg-icons';
import Boton from '../components/Boton';
import ModalError from '../components/ModalDeError';

interface BusquedaMasDeseadasProps {
	listadoNivel: string[];
	listadoInteres: string[];
	error: boolean;
	mensajeDeError: string;
	onCerrarModal: () => void;
	habiliadesPorMayorConocimiento: boolean;
	habiliadesPorMayorInteres: boolean;
	onCambiarSentidoHabilidades: (PorConocimiento?: boolean) => void;
	descargarArchivo: () => void;
}

const BusquedaMasDeseadasView = ({
	listadoNivel,
	listadoInteres,
	error,
	mensajeDeError,
	onCerrarModal,
	habiliadesPorMayorConocimiento,
	habiliadesPorMayorInteres,
	onCambiarSentidoHabilidades,
	descargarArchivo,
}: BusquedaMasDeseadasProps) => {
	return (
		<>
			<Contenedor>
				<Header>
					<Titulo
						mayor={true}
						texto={'Habilidades más deseadas'}
					/>
				</Header>
				<ListaPersonalizada>
					<ListaNivel>
						<HeaderLista>
							<Texto>Por Conocimiento</Texto>
							<BotonDeOrdenDeLista
								data-cy={`boton-ordenar-lista-conocimiento`}
								onClick={() => {
									onCambiarSentidoHabilidades(true);
								}}
							>
								{habiliadesPorMayorConocimiento ? (
									<FontAwesomeIcon
										icon={faArrowUp}
										color={`${Colores.BLANCO_PURO}`}
									/>
								) : (
									<FontAwesomeIcon
										icon={faArrowDown}
										color={`${Colores.BLANCO_PURO}`}
									/>
								)}
							</BotonDeOrdenDeLista>
						</HeaderLista>
						<ContenedorDeHabilidadesMasDeseadas
							habilidadesMasDeseadas={listadoNivel}
						/>
					</ListaNivel>
					<ListaNivel>
						<HeaderLista>
							<Texto>Por Interes</Texto>
							<BotonDeOrdenDeLista
								data-cy={`boton-ordenar-lista-interes`}
								onClick={() => {
									onCambiarSentidoHabilidades(false);
								}}
							>
								{habiliadesPorMayorInteres ? (
									<FontAwesomeIcon
										icon={faArrowUp}
										color={Colores.BLANCO_PURO}
									/>
								) : (
									<FontAwesomeIcon
										icon={faArrowDown}
										color={Colores.BLANCO_PURO}
									/>
								)}
							</BotonDeOrdenDeLista>
						</HeaderLista>
						<ContenedorDeHabilidadesMasDeseadas
							habilidadesMasDeseadas={listadoInteres}
						/>
					</ListaNivel>
				</ListaPersonalizada>
				<ContenedorBotonDescargar>
					<p>Si queres ver la lista completa la podes descargar</p>
					<Boton
						texto={'Descargar'}
						onSubmit={descargarArchivo}
					/>
				</ContenedorBotonDescargar>
			</Contenedor>
			{error && (
				<ModalError
					mensajeDeError={mensajeDeError}
					cerrarModal={onCerrarModal}
				/>
			)}
		</>
	);
};

const ContenedorBotonDescargar = styled.div`
	display: flex;
	justify-content: center;
	flex-direction: column;
	align-items: center;
	width: 100%;
`;

const Texto = styled.h3`
	text-align: center;
	display: flex;
	margin: 0 ${Grilla.S};
	align-items: flex-start;
	justify-content: center;
	width: 100%;
`;
const Header = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
`;

const HeaderLista = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
`;

const BotonDeOrdenDeLista = styled.button`
	background-color: ${Colores.VIOLETA};
	display: flex;
	align-items: center;
	justify-content: center;
	width: 29px;
	height: 23px;
	border-radius: 50%;
	border: none;
	cursor: pointer;
`;

const ListaNivel = styled.div`
	display: flex;
	padding-bottom: ${Grilla.M};
	width: 260px;
	height: 300px;
	align-items: center;
	flex-direction: column;
	justify-content: space-around;
	border: 1px solid ${Colores.VIOLETA};
	border-radius: ${Grilla.SS};
	box-sizing: border-box;
	margin: ${Grilla.S} ${Grilla.L};
`;

const ListaPersonalizada = styled.div`
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
`;

export default BusquedaMasDeseadasView;
