import styled from 'styled-components';
import InfoHabilidad from './InfoHabilidad';
import { v4 as uuidv4 } from 'uuid';

interface ContenedorDeHabilidadesMasDeseadasProps {
	habilidadesMasDeseadas: string[];
}

const ContenedorDeHabilidadesMasDeseadas = ({
	habilidadesMasDeseadas,
}: ContenedorDeHabilidadesMasDeseadasProps) => {
	return (
		<BarraDeBusqueda>
			{habilidadesMasDeseadas.slice(0, 6).map(e => (
				<InfoHabilidad
					key={uuidv4()}
					texto={e}
					ensanchar={true}
				/>
			))}
		</BarraDeBusqueda>
	);
};

const BarraDeBusqueda = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	right: 0;
	width: 70%;
	flex-wrap: wrap;
	overflow: hidden;
`;

export default ContenedorDeHabilidadesMasDeseadas;
