import styled from 'styled-components';

interface EmojiProps {
	simbolo: string;
	label?: string;
}

const Emoji = ({ simbolo, label }: EmojiProps) => (
	<EmojiPersonalizado
		role="img"
		aria-label={label ? label : ''}
		aria-hidden={label ? 'false' : 'true'}
	>
		{simbolo}
	</EmojiPersonalizado>
);
export default Emoji;

const EmojiPersonalizado = styled.span`
	margin: 3px;
`;
