import styled from 'styled-components';
import { Grilla } from '../constantes/style';

interface SeparadorProps {
	altura?: string;
}

const Separador = ({ altura }: SeparadorProps) => {
	return <SeparadorPersonalizado altura={altura} />;
};

const SeparadorPersonalizado = styled.div<{ altura?: string }>`
	height: ${props => props.altura || Grilla.S};
`;

export default Separador;
