import styled from 'styled-components';
import { Colores, Grilla } from '../constantes/style';
interface ModalProps {
	titulo: string;
	children: React.ReactNode;
	cerrarModal: () => void;
}

const Modal = ({ titulo, children, cerrarModal }: ModalProps) => {
	return (
		<ContenedorModal>
			<ContenedorContenido>
				<Titulo>{titulo}</Titulo>
				{children}
				<ContenedorBoton>
					<BotonCerrar
						onClick={cerrarModal}
						data-cy={`boton-cerrar-modal`}
					>
						×
					</BotonCerrar>
				</ContenedorBoton>
			</ContenedorContenido>
		</ContenedorModal>
	);
};
const Titulo = styled.h3`
	margin: 0;
	text-transform: uppercase;
`;

const ContenedorModal = styled.div`
	z-index: 1;
	width: 100%;
	height: 100%;
	position: fixed;
	display: flex;
	align-items: center;
	justify-content: center;
	top: 0;
	left: 0;
	background-color: rgba(0, 0, 0, 0.4);
`;

const ContenedorContenido = styled.div`
	display: flex;
	align-items: flex-start;
	flex-direction: column;
	background-color: ${Colores.BLANCO_PURO};
	border-radius: 30px;
	box-shadow: 0 4px 50px rgb(0 0 0 / 15%);
	box-sizing: border-box;
	height: auto;
	padding: 26px 35px;
	position: relative;
`;

const ContenedorBoton = styled.div`
	position: absolute;
	right: ${Grilla.M};
	top: ${Grilla.XS};
`;

const BotonCerrar = styled.button`
	padding-top: 0;
	background-color: transparent;
	border: none;
	font-family: 'Dosis', sans-serif;
	font-weight: 700;
	font-size: 1.75rem;
	&:hover,
	&:focus {
		color: ${Colores.VIOLETA_ACTIVO};
		cursor: pointer;
	}
`;

export default Modal;
