import styled from 'styled-components';
interface TituloProps {
	texto: string;
	mayor?: boolean;
	dataCy?: string;
}

const Titulo = ({ texto, dataCy = 'Titulo', mayor }: TituloProps) => {
	return mayor ? (
		<TextoMayor data-cy={dataCy}>{texto}</TextoMayor>
	) : (
		<Texto data-cy={dataCy}>{texto}</Texto>
	);
};

const TextoMayor = styled.h2`
	margin-top: 0;
`;

const Texto = styled.h3`
	margin-top: 0;
`;
export default Titulo;
