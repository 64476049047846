import styled from 'styled-components';
import Contenedor from '../components/Contenedor';
import Titulo from '../components/Titulo';
import IllustrationTeam from '../img/illustration-team.svg';
import { Grilla } from '../constantes/style';
import Imagen from '../components/Imagen';
import Boton from '../components/Boton';

interface LoginViewProps {
	login: () => void;
}
const imgsrc = IllustrationTeam;
const LoginView = ({ login }: LoginViewProps) => {
	return (
		<Contenedor
			alingItemCenter={true}
			width="350px"
		>
			<Imagen
				src={imgsrc}
				alt={'team working'}
				dataCy={'Esfera-logo'}
			/>
			<Titulo
				texto={'Ingresá a la App de Habilidades'}
				dataCy={'Titulo-login'}
			/>
			<Boton
				texto={'Login'}
				onSubmit={login}
				dataCy={'Boton-login'}
			/>
			<Texto data-cy={'Texto-login'}>
				{
					'💡 Si se te ocurren ideas para mejorar este proyecto, no dudes en contactarnos!'
				}
			</Texto>
		</Contenedor>
	);
};

const Texto = styled.p`
	text-align: center;
	font-size: ${Grilla.SS};
	margin-bottom: 0;
	margin-top: 20px;
`;

export default LoginView;
