import styled from 'styled-components';

interface ImagenProps {
	src: any;
	alt: string;
	dataCy?: string;
}

const Imagen = ({ src, alt, dataCy = 'Imagen' }: ImagenProps) => {
	return (
		<ImagenPersonalizada
			src={src}
			alt={alt}
			data-cy={dataCy}
		/>
	);
};

const ImagenPersonalizada = styled.img`
	margin-top: 5px;
	width: 100px;
`;
export default Imagen;
