import axios, { AxiosInstance } from 'axios';

class ClienteAPI {
	private static instancia: AxiosInstance;

	public static obtenerInstancia(): AxiosInstance {
		if (!this.instancia) {
			this.instancia = axios.create({
				baseURL: process.env.REACT_APP_BACK_URL,
				timeout: 2000,
			});
		}
		return this.instancia;
	}
}

export default ClienteAPI.obtenerInstancia();
