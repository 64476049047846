import { useState } from 'react';
import { Habilidad } from '../types/Habilidad';
import { Usuario } from '../types/Usuario';
import clienteApi from '../util/clienteApi';

export const useBusquedaDeUsuario = () => {
	const [buscandoPerfiles, setBuscandoPerfiles] = useState<boolean>(false);

	const [errorConsultaUsuario, setErrorConsultaUsuario] =
		useState<boolean>(false);

	let mensajeDeErrorConsultaUsuairo: string = '';

	const listarUsuarioPorHabilidades = async (
		habilidadesABuscar: Habilidad[],
	): Promise<Usuario[]> => {
		setBuscandoPerfiles(true);

		let listaDeUsuarios: Usuario[] = [];
		const idHabilidadesABuscar = habilidadesABuscar.map(h => h.id);
		try {
			const response = await clienteApi.get(
				`/usuarios?habilidades=${idHabilidadesABuscar}`,
			);
			listaDeUsuarios = response.data;
		} catch (e: any) {
			setErrorConsultaUsuario(true);
			mensajeDeErrorConsultaUsuairo = e.message;
		} finally {
			setBuscandoPerfiles(false);
			return listaDeUsuarios;
		}
	};
	return {
		listarUsuarioPorHabilidades,
		setErrorConsultaUsuario,
		buscandoPerfiles,
		errorConsultaUsuario,
		mensajeDeErrorConsultaUsuairo,
	};
};
