import { TailSpin } from 'react-loader-spinner';
import styled from 'styled-components';
import Contenedor from '../components/Contenedor';
import ContenedorDeFiltrosHabilidad from '../components/ContenedorDeFiltrosHabilidad';
import Lista from '../components/Lista';
import Titulo from '../components/Titulo';
import { Colores, Grilla } from '../constantes/style';
import { Icono } from '../constantes/iconos';
import { Habilidad } from '../types/Habilidad';
import { Usuario } from '../types/Usuario';
import Emoji from '../components/Emoji';
import ComboCustom from '../components/ComboCustom';
import RepuestaDeComponente from '../util/RespuestaComponente';
import ModalError from '../components/ModalDeError';

interface BusquedaDeUsuarioViewProps {
	habilidades: Habilidad[];
	habilidadesABuscar: Habilidad[];
	resultadoBusqueda: Usuario[];
	buscandoPerfiles: boolean;
	error: boolean;
	mensajeDeError: string;
	busquedaRealizada: boolean;
	onAgregarHabilidadAlFiltro: (respuesta: RepuestaDeComponente) => void;
	onEliminarFiltroHabilidad: (idHabilidad: string) => void;
	onCerrarModal: () => void;
}

const BusquedaDeUsuarioView = ({
	habilidades,
	habilidadesABuscar,
	resultadoBusqueda,
	buscandoPerfiles,
	busquedaRealizada,
	onAgregarHabilidadAlFiltro,
	onEliminarFiltroHabilidad,
	error,
	mensajeDeError,
	onCerrarModal,
}: BusquedaDeUsuarioViewProps) => {
	return (
		<>
			<Contenedor>
				<Titulo texto={'Buscar personas por habilidades'} />
				<ContenedorBuscador>
					<ContenedorBotones>
						<ComboCustom
							id={'Habilidades'}
							listado={habilidades}
							titulo={'Habilidad/tecnología'}
							textoInformativo={
								'Selecciona Habilidad/tecnología para buscar perfiles compatibles'
							}
							onChange={onAgregarHabilidadAlFiltro}
						/>
					</ContenedorBotones>
					<ContenedorDeFiltrosHabilidad
						habilidadesABuscar={habilidadesABuscar}
						onEliminarFiltroHabilidad={onEliminarFiltroHabilidad}
					/>
				</ContenedorBuscador>
				{!!buscandoPerfiles && (
					<TailSpin
						color="${Colores.VIOLETA}"
						visible={true}
						height={50}
						width={50}
					/>
				)}
				{resultadoBusqueda.length === 0 &&
					busquedaRealizada &&
					habilidadesABuscar.length != 0 && (
						<h4>No se encontraron resultados para esta busqueda.</h4>
					)}
				{!!resultadoBusqueda.length && (
					<>
						<TituloDeResultados>Resultados</TituloDeResultados>
						<Lista listado={resultadoBusqueda} />
						<FooterInstructivo>
							<BloqueDeInstruccion>
								<ContenidoDeInstruccion>
									<TituloDeInformacion>
										Nivel de Interés:
									</TituloDeInformacion>
									<Texto>
										<Emoji simbolo={Icono.INTERESBAJO} /> Bajo
									</Texto>
									<Texto>
										<Emoji simbolo={Icono.INTERESMEDIO} /> Medio
									</Texto>
									<Texto>
										<Emoji simbolo={Icono.INTERESALTO} /> Alto
									</Texto>
								</ContenidoDeInstruccion>
							</BloqueDeInstruccion>
							<BloqueDeInstruccion>
								<ContenidoDeInstruccion>
									<TituloDeInformacion>
										Nivel de Conocimiento:
									</TituloDeInformacion>
									<Texto>
										<Emoji simbolo={Icono.NIVELBAJO} /> Bajo
									</Texto>
									<Texto>
										<Emoji simbolo={Icono.NIVELMEDIO} /> Medio
									</Texto>
									<Texto>
										<Emoji simbolo={Icono.NIVELALTO} /> Alto
									</Texto>
								</ContenidoDeInstruccion>
							</BloqueDeInstruccion>
						</FooterInstructivo>
					</>
				)}
			</Contenedor>
			{error && (
				<ModalError
					mensajeDeError={mensajeDeError}
					cerrarModal={onCerrarModal}
				/>
			)}
		</>
	);
};

const Texto = styled.text`
	margin-left: 10px;
`;

const ContenedorBuscador = styled.div`
	display: flex;
	padding-bottom: ${Grilla.M};
	position: relative;
	width: 1000px;
`;
const ContenedorBotones = styled.div`
	display: flex;
	align-items: center;
	margin-right: 15px;
`;
const FooterInstructivo = styled.div`
	display: flex;
	margin-top: 30px;
	justify-content: space-around;
	width: 100%;
`;
const BloqueDeInstruccion = styled.div`
	margin-left: 2px;
	border: 1px solid ${Colores.VIOLETA};
	border-radius: 10px;
	padding: 10px;
	box-sizing: border-box;
`;
const TituloDeResultados = styled.h4`
	display: flex;
	margin: 20px 0px 0px 0px;
`;
const TituloDeInformacion = styled.h4`
	display: flex;
	margin: 0px;
`;

const ContenidoDeInstruccion = styled.div`
	display: flex;
	justify-content: space-around;
`;
const MensajeDeError = styled.p`
	padding-right: 30px;
`;

export default BusquedaDeUsuarioView;
