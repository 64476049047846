import {
	GoogleAuthProvider,
	signInWithPopup,
	getAuth,
	signOut,
} from 'firebase/auth';
import { authFirebase } from '../config/firebase-config';
import { useAuthState } from 'react-firebase-hooks/auth';

export const useAuthUser = () => {
	const [user, loading, error] = useAuthState(authFirebase);

	const login = () => {
		const provider = new GoogleAuthProvider();

		return signInWithPopup(authFirebase, provider);
	};

	const logout = () => {
		const auth = getAuth();
		signOut(auth).then(() => {});
	};

	return { login, logout, user, loading, error };
};
