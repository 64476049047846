import { useCallback, useEffect, useState } from 'react';
import { useHabilidades } from '../hooks/useHabilidades';
import { useBusquedaDeUsuario } from '../hooks/useBusquedaDeUsuario';
import { Habilidad } from '../types/Habilidad';
import { Usuario } from '../types/Usuario';
import BusquedaDeUsuarioView from '../views/BusquedaUsuariosView';
import RepuestaDeComponente from '../util/RespuestaComponente';

const BusquedaUsuarioScreen = () => {
	const usuarioPorDefecto: Usuario[] = [];
	let mensajeDeError: string;
	const [habilidades, setHabilidades] = useState<Habilidad[]>([]);
	const [habilidadesABuscar, setHabilidadesABuscar] = useState<Habilidad[]>(
		[],
	);
	const [resultado, setResultado] = useState<Usuario[]>(usuarioPorDefecto);
	const [busquedaRealizada, setBusquedaRealizada] = useState<boolean>(false);
	const {
		listarHabilidades,
		errorConsultaHabilidad,
		setErrorConsultaHabilidad,
		mensajeDeErrorHabilidades,
	} = useHabilidades();
	const {
		listarUsuarioPorHabilidades,
		errorConsultaUsuario,
		setErrorConsultaUsuario,
		mensajeDeErrorConsultaUsuairo,
		buscandoPerfiles,
	} = useBusquedaDeUsuario();

	const llamandoAlBack = async () => {
		const habilidadesDesdeElBack: Habilidad[] = await listarHabilidades();
		setHabilidades(habilidades.concat(habilidadesDesdeElBack));
	};

	const eliminarFiltroHabilidad = useCallback(
		async (idHabilidad: string) => {
			const filtroHabilidades = habilidadesABuscar.filter(
				habilidad => habilidad.id !== idHabilidad,
			);
			setHabilidadesABuscar(filtroHabilidades);
			await buscarHabilidad(filtroHabilidades);
		},
		[habilidadesABuscar],
	);

	const onAgregarHabilidadAlFiltro = useCallback(
		async (respuesta: RepuestaDeComponente) => {
			const habilidadNueva: Habilidad | undefined = respuesta.valor;
			if (
				habilidadNueva &&
				!habilidadesABuscar.find(h => h.id === habilidadNueva.id)
			) {
				const habilidadNuevasAFiltrar = [
					...habilidadesABuscar,
					habilidadNueva,
				];
				setHabilidadesABuscar(habilidadNuevasAFiltrar);
				await buscarHabilidad(habilidadNuevasAFiltrar);
			}
		},
		[habilidades, habilidadesABuscar],
	);

	const buscarHabilidad = useCallback(
		async (listaDeHabilidades: Habilidad[]) => {
			const listaDePerfiles = await listarUsuarioPorHabilidades(
				listaDeHabilidades,
			);
			setBusquedaRealizada(true);
			setResultado(listaDePerfiles);
		},
		[habilidadesABuscar],
	);

	const cerrarModal = () => {
		setErrorConsultaHabilidad(false);
		setErrorConsultaUsuario(false);
	};

	const obtenerMsjDeError = (): string => {
		let mensajeAMostrar = 'Ocurrio un error pofavor intente mas tarde';
		if (mensajeDeErrorConsultaUsuairo !== '') {
			mensajeAMostrar = mensajeDeErrorConsultaUsuairo;
		}
		if (mensajeDeErrorHabilidades !== '') {
			mensajeAMostrar = mensajeDeErrorHabilidades;
		}
		return mensajeAMostrar;
	};

	useEffect(() => {
		llamandoAlBack();
		setBusquedaRealizada(false);
	}, []);
	mensajeDeError = obtenerMsjDeError();
	return (
		<BusquedaDeUsuarioView
			habilidades={habilidades}
			habilidadesABuscar={habilidadesABuscar}
			resultadoBusqueda={resultado}
			buscandoPerfiles={buscandoPerfiles}
			busquedaRealizada={busquedaRealizada}
			onAgregarHabilidadAlFiltro={onAgregarHabilidadAlFiltro}
			onEliminarFiltroHabilidad={eliminarFiltroHabilidad}
			onCerrarModal={cerrarModal}
			error={errorConsultaHabilidad || errorConsultaUsuario}
			mensajeDeError={mensajeDeError}
		/>
	);
};

export default BusquedaUsuarioScreen;
