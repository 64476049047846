import styled from 'styled-components';
import { Colores, Grilla } from '../constantes/style';
import { Icono } from '../constantes/iconos';
import Emoji from './Emoji';

interface InfoHabilidadesProps {
	texto: string;
	interes?: string;
	nivel?: string;
	ensanchar?: boolean;
	onEliminarFiltroHabilidad?: () => void;
}

const InfoHabilidad = ({
	texto,
	interes,
	nivel,
	ensanchar,
	onEliminarFiltroHabilidad,
}: InfoHabilidadesProps) => {
	return (
		<ContenedorPersonalizada
			data-cy={`habilidad-${texto}`}
			ensanchar={ensanchar}
		>
			<Texto>{texto}</Texto>
			{interes && <IconoInteres interes={interes} />}
			{nivel && <IconoNivel nivel={nivel} />}
			{onEliminarFiltroHabilidad && (
				<BotonCerrar
					data-cy={`boton-habilidad-${texto}`}
					onClick={onEliminarFiltroHabilidad}
				>
					×
				</BotonCerrar>
			)}
		</ContenedorPersonalizada>
	);
};

const IconoNivel = ({ nivel }: any) => {
	let icono = '#';
	switch (nivel) {
		case 'Bajo':
			icono = Icono.NIVELBAJO;
			break;
		case 'Intermedio':
			icono = Icono.NIVELMEDIO;
			break;
		case 'Alto':
			icono = Icono.NIVELALTO;
			break;
	}
	return <Emoji simbolo={icono} />;
};

const IconoInteres = ({ interes }: any) => {
	let icono = '#';
	switch (interes) {
		case 'Bajo':
			icono = Icono.INTERESBAJO;
			break;
		case 'Intermedio':
			icono = Icono.INTERESMEDIO;
			break;
		case 'Alto':
			icono = Icono.INTERESALTO;
			break;
	}
	return <Emoji simbolo={icono} />;
};

const ContenedorPersonalizada = styled.div<{ ensanchar?: boolean }>`
	background-color: ${Colores.VIOLETA};
	border: none;
	border-radius: 10px;
	color: ${Colores.BLANCO_PURO};
	display: flex;
	align-items: center;
	margin: 3px;
	padding: 0 ${Grilla.XS} 2px ${Grilla.S};
	font-weight: 500;
	${props =>
		props.ensanchar ? ` justify-content:center; width:120px; ` : null}
	overflow: hidden;
`;

const BotonCerrar = styled.button`
	border: none;
	background-color: inherit;
	color: inherit;
`;

const Texto = styled.p`
	margin: 3px;
`;

export default InfoHabilidad;
