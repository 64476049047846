import clienteApi from '../util/clienteApi';
import { useState } from 'react';
import fileDownload from 'js-file-download';

export const useHabilidadesMasDeseadas = () => {
	const [
		errorBuscandoHabilidadesMasDeseadas,
		setErrorBuscandoHabilidadesMasDeseadas,
	] = useState<boolean>(false);
	const [errorDescargandoArchivo, setErrorDescargandoArchivo] =
		useState<boolean>(false);

	let mensajeDeErrorHabilidades: string = '';

	const listarHabilidadesMasDeseadas = async (): Promise<string[][]> => {
		let lista: string[][] = [];
		try {
			const response = await clienteApi.get('/habilidadesMasDeseadas');
			lista = response.data;
			setErrorBuscandoHabilidadesMasDeseadas(false);
		} catch (e: any) {
			setErrorBuscandoHabilidadesMasDeseadas(true);
			mensajeDeErrorHabilidades = e.message;
		} finally {
			return lista;
		}
	};

	const obtenerArchivoConListaDeHabilidades = async (): Promise<any> => {
		let archivo: any;
		const nombreDelArchivo = 'ResumenHabilidadesYIntereses.xlsx';
		try {
			const response = await clienteApi.get(
				'/habilidadesMasDeseadas/archivo',
				{ responseType: 'blob' },
			);
			archivo = response.data;
			setErrorDescargandoArchivo(false);
		} catch (e: any) {
			setErrorDescargandoArchivo(true);
			mensajeDeErrorHabilidades = e.message;
		} finally {
			fileDownload(archivo, nombreDelArchivo);
		}
	};

	return {
		listarHabilidadesMasDeseadas,
		setErrorBuscandoHabilidadesMasDeseadas,
		errorBuscandoHabilidadesMasDeseadas,
		obtenerArchivoConListaDeHabilidades,
		errorDescargandoArchivo,
	};
};
