import styled from 'styled-components';
import { Grilla } from '../constantes/style';
import { TITULO_MODAL_ERROR } from '../constantes/titulos';
import Boton from './Boton';
import Modal from './Modal';
interface ModalErrorProps {
	mensajeDeError: string;
	cerrarModal: () => void;
}

const ModalError = ({ mensajeDeError, cerrarModal }: ModalErrorProps) => {
	return (
		<Modal
			titulo={TITULO_MODAL_ERROR}
			children={
				<ContenedorModal>
					<MensajeDeError>{mensajeDeError}</MensajeDeError>
					<Boton
						texto={'ACEPTAR'}
						onSubmit={cerrarModal}
					/>
				</ContenedorModal>
			}
			cerrarModal={cerrarModal}
		/>
	);
};

const ContenedorModal = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	position: relative;
	box-sizing: border-box;
	overflow-wrap: anywhere;
	padding: 26px 35px;
	max-width: 500px;
`;

const MensajeDeError = styled.p`
	padding-right: ${Grilla.L};
	text-align: center;
`;

export default ModalError;
