import styled from 'styled-components';
import { Colores, Grilla } from '../constantes/style';

interface BotonProps {
	texto: any;
	onSubmit: () => void;
	width?: string;
	fontSize?: string;
	disabled?: boolean;
	color?: string;
	activColor?: string;
	dataCy?: string;
}

const Boton = ({
	onSubmit,
	texto,
	width = Grilla.XXXL,
	fontSize = '14px',
	disabled = false,
	color = Colores.VIOLETA,
	activColor = Colores.VIOLETA_ACTIVO,
	dataCy = 'Boton',
}: BotonProps) => {
	return (
		<BotonPersonalizado
			onClick={onSubmit}
			width={width}
			fontSize={fontSize}
			disabled={disabled}
			color={color}
			activColor={activColor}
			data-cy={dataCy}
		>
			{texto}
		</BotonPersonalizado>
	);
};

const BotonPersonalizado = styled.button<{
	width: string;
	fontSize: string;
	color: string;
	activColor: string;
	disabled: boolean;
}>`
	background-color: ${props => props.color};
	border: none;
	border-radius: 14px;
	color: ${Colores.BLANCO_PURO};
	font-family: 'Dosis', sans-serif;
	font-weight: 700;
	font-size: ${props => props.fontSize};
	padding: 6px 10px;
	text-transform: uppercase;
	width: ${props => props.width};
	height: 30px;
	&:hover,
	&:focus,
	&:active {
		background-color: ${props => props.activColor};
		cursor: pointer;
	},
	${props =>
		props.disabled &&
		`&:disabled{background-color: #cccccc;
			color: #666666}`}
`;

export default Boton;
