import { useState } from 'react';
import styled from 'styled-components';
import { DetalleHabilidad } from '../types/DetalleHabilidad';
import Modal from './Modal';
import TablaDetalleHabilidades from './TablaDetalleHabilidades';
import { Grilla, Colores } from '../constantes/style';
import { faEye } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { v4 as uuidv4 } from 'uuid';
import InfoHabilidad from './InfoHabilidad';
import Tooltip from './Tooltip';

interface EtiquetaProps {
	nombre: string | null | undefined;
	avatarUrl?: string | null | undefined;
	detalleHabilidades: DetalleHabilidad[];
	masInformacion: () => boolean;
	fechaUltimaActualizacion?: string;
}

const ResultadoHabilidadesDeUsuario = ({
	nombre,
	avatarUrl,
	detalleHabilidades,
	masInformacion,
	fechaUltimaActualizacion,
}: EtiquetaProps) => {
	const [debeMostrarModalDeDetalles, setDebeMostrarModalDeDetalles] =
		useState(false);
	return (
		<ContenedorPrincipal data-cy={`resultado-busqueda-${nombre}`}>
			<ContenedorDatosUsuario>
				<ContenedorDeAvatarYNombre>
					{avatarUrl && <Avatar src={avatarUrl} />}
					<Texto>{nombre}</Texto>
				</ContenedorDeAvatarYNombre>
				<TextoFecha>{`Ultima actualización: ${fechaUltimaActualizacion}`}</TextoFecha>
			</ContenedorDatosUsuario>
			<ContenedorSecundario>
				<Texto>Habilidades/Intereses: </Texto>
				<ContenedorHabilidades>
					{detalleHabilidades.map(d => {
						const autoKey = uuidv4();
						return (
							<Tooltip
								key={autoKey}
								todo={true}
								textoInfoHabilidad={d.habilidad.nombre}
								interes={d.interes.nombre}
								nivel={d.nivel.nombre}
							/>
						);
					})}
				</ContenedorHabilidades>
			</ContenedorSecundario>
			{masInformacion() && (
				<ContenedorBoton>
					<BotonVerMas onClick={() => setDebeMostrarModalDeDetalles(true)}>
						<FontAwesomeIcon icon={faEye} />
					</BotonVerMas>
				</ContenedorBoton>
			)}
			{debeMostrarModalDeDetalles && nombre && (
				<Modal
					titulo={nombre}
					children={
						<TablaDetalleHabilidades
							width={'1000px'}
							detalleHabilidades={detalleHabilidades}
						/>
					}
					cerrarModal={() => setDebeMostrarModalDeDetalles(false)}
				/>
			)}
		</ContenedorPrincipal>
	);
};

const ContenedorSecundario = styled.div`
	display: flex;
	flex-direction: column;
	min-width: 400px;
	max-width: 600px;
`;

const ContenedorHabilidades = styled(ContenedorSecundario)`
	flex-direction: row;
	flex-wrap: wrap;
	min-width: 400px;
`;

const ContenedorPrincipal = styled.div`
	border: 1px solid ${Colores.VIOLETA};
	border-radius: 14px;
	padding: 10px;
	box-sizing: border-box;
	height: auto;
	min-height: 56px;
	display: flex;
	margin-bottom: ${Grilla.S};
	flex-wrap: wrap;
	position: relative;
`;

const Texto = styled.span`
	margin: 3px;
`;

const TextoFecha = styled.span`
	font-size: 0.75rem;
	margin-left: ${Grilla.S};
	margin-top: ${Grilla.XS};
`;

const ContenedorDeAvatarYNombre = styled.div`
	align-items: center;
	display: flex;
`;

const Avatar = styled.img`
	border-radius: 50%;
	width: 28px;
	height: 28px;
	margin: 0 10px;
`;

const ContenedorDatosUsuario = styled.div`
	width: 250px;
	display: flex;
	flex-direction: column;
`;

const ContenedorBoton = styled.div`
	position: absolute;
	right: ${Grilla.S};
`;

const BotonVerMas = styled.button`
	border: none;
	background-color: transparent;
	cursor: pointer;
	color: ${Colores.VIOLETA};
	position: absolute;
	right: 0;
`;

export default ResultadoHabilidadesDeUsuario;
