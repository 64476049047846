import styled from 'styled-components';
import { Colores } from '../constantes/style';

interface ContenedorProps {
	children: React.ReactNode;
	flexRow?: boolean;
	alingItemCenter?: boolean;
	width?: string;
}

const Contenedor = ({
	flexRow,
	alingItemCenter,
	children,
	width,
}: ContenedorProps) => {
	return (
		<ContenedorPersonalizado
			flexRow={flexRow}
			alingItemCenter={alingItemCenter}
			width={width}
		>
			{children}
		</ContenedorPersonalizado>
	);
};

const ContenedorPersonalizado = styled.div<{
	flexRow?: boolean;
	alingItemCenter?: boolean;
	width?: string;
}>`
	${props =>
		props.alingItemCenter
			? ` align-items: center`
			: ` align-items: flex-start`};
	${props =>
		props.flexRow ? ` flex-direction: row` : ` flex-direction: column`};
	${props => (props.width ? ` width: ${props.width}` : ` width:1070px`)};
	background-color: ${Colores.BLANCO_PURO};
	border-radius: 30px;
	box-shadow: 0 4px 50px rgb(0 0 0 / 15%);
	box-sizing: border-box;
	display: flex;
	height: auto;
	padding: 26px 35px;
	position: relative;
	max-height: 80vh;
`;

export default Contenedor;
