import React from 'react';
import Boton from '../components/Boton';
import Separador from '../components/Separador';
import Titulo from '../components/Titulo';
import { Colores, Grilla } from '../constantes/style';
import { TOOLTIP_NIVEL, TOOLTIP_INTERES } from '../constantes/combos';
import { DetalleHabilidad } from '../types/DetalleHabilidad';
import { Habilidad } from '../types/Habilidad';
import { Interes } from '../types/Interes';
import { Nivel } from '../types/Nivel';
import Contenedor from '../components/Contenedor';
import TablaDetalleHabilidades from '../components/TablaDetalleHabilidades';
import styled from 'styled-components';
import { TailSpin } from 'react-loader-spinner';
import InputConSugerencias from '../components/InputConSugerencias';
import RepuestaDeComponente from '../util/RespuestaComponente';
import ComboCustom from '../components/ComboCustom';
import TextArea from '../components/TextArea';
import ModalError from '../components/ModalDeError';

interface HabilidadesABMViewProps {
	habilidades: Habilidad[];
	niveles: Nivel[];
	intereses: Interes[];
	detalleHabilidades: DetalleHabilidad[];
	habilidadEnEdicion: DetalleHabilidad;
	error: boolean;
	mensajeDeError: string;
	seleccionValida: boolean;
	agregandoDetalleHabilidad: boolean;
	cargandoDetalleHabilidad: boolean;
	debeMostrarModalDeEdicion: boolean;
	habilidadSeleccionada: Habilidad | undefined;
	nivelSeleccionada: Nivel | undefined;
	intereSeleccionada: Interes | undefined;
	onInputChange: (respuesta: RepuestaDeComponente) => void;
	onSubmit: () => void;
	onSubmitEditor: () => void;
	onEliminarDetalleHabilidad: (id: string) => void;
	onObservacionesChange: (
		evento: React.ChangeEvent<HTMLTextAreaElement>,
	) => void;
	onCerrarModal: () => void;
	setHabilidadEnEdicion: (habilidad: DetalleHabilidad) => void;
	setDebeMostrarModalDeEdicion: (val: boolean) => void;
}

const HabilidadesABMView = ({
	habilidades,
	niveles,
	intereses,
	detalleHabilidades,
	habilidadEnEdicion,
	error,
	mensajeDeError,
	seleccionValida,
	habilidadSeleccionada,
	agregandoDetalleHabilidad,
	nivelSeleccionada,
	intereSeleccionada,
	onInputChange,
	onSubmit,
	onSubmitEditor,
	onEliminarDetalleHabilidad,
	onCerrarModal,
	onObservacionesChange,
	setHabilidadEnEdicion,
	setDebeMostrarModalDeEdicion,
	debeMostrarModalDeEdicion,
}: HabilidadesABMViewProps) => {
	return (
		<>
			<Contenedor>
				<Titulo texto={'Cargá tus habilidades'} />
				<ContenedorCombos>
					<InputConSugerencias
						id={'habilidades'}
						listado={habilidades}
						titulo={'Habilidad/tecnología'}
						textoInformativo={
							'seleccioná una habilidad/tecnología para cargar a tu perfil'
						}
						onChange={onInputChange}
						itemSeleccionado={habilidadSeleccionada}
					/>
					<ComboCustom
						id={'niveles'}
						listado={niveles}
						titulo={'Nivel de conocimiento'}
						textoInformativo={TOOLTIP_NIVEL}
						onChange={onInputChange}
						itemSeleccionado={nivelSeleccionada}
					/>
					<ComboCustom
						id={'intereses'}
						listado={intereses}
						titulo={'Nivel de interés'}
						textoInformativo={TOOLTIP_INTERES}
						onChange={onInputChange}
						itemSeleccionado={intereSeleccionada}
					/>
				</ContenedorCombos>
				<TextArea
					id={'Observaciones'}
					limiteCaracteres={140}
					onObservacionesChage={onObservacionesChange}
					placeHolder={'Observaciones'}
				/>
				<Separador altura={Grilla.M} />
				{!!agregandoDetalleHabilidad ? (
					<TailSpin
						color={`${Colores.VIOLETA}`}
						visible={true}
						height={50}
						width={50}
					/>
				) : (
					<Boton
						disabled={!seleccionValida}
						texto={'Guardar'}
						onSubmit={onSubmit}
						dataCy={'boton-guardar-nueva-habilidad'}
					/>
				)}

				<Separador altura={Grilla.M} />
				{detalleHabilidades.length !== 0 && (
					<TablaDetalleHabilidades
						detalleHabilidades={detalleHabilidades}
						onEliminarDetalleHabilidad={onEliminarDetalleHabilidad}
						botonEdicionHabilitado={true}
						onSubmitEditor={onSubmitEditor}
						onInputChange={onInputChange}
						onObservacionesChangeEditor={onObservacionesChange}
						setHabilidadEnEdicion={setHabilidadEnEdicion}
						habilidadEnEdicion={habilidadEnEdicion}
						intereses={intereses}
						niveles={niveles}
						debeMostrarModalDeEdicion={debeMostrarModalDeEdicion}
						setDebeMostrarModalDeEdicion={setDebeMostrarModalDeEdicion}
					/>
				)}
			</Contenedor>
			{error && (
				<ModalError
					mensajeDeError={mensajeDeError}
					cerrarModal={onCerrarModal}
				/>
			)}
		</>
	);
};

const ContenedorCombos = styled.div`
	display: flex;
	justify-content: space-between;
	width: 100%;
`;

export default HabilidadesABMView;
