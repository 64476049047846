import styled from 'styled-components';
import { Colores, Grilla } from '../constantes/style';
import { Fragment, useState } from 'react';
import { Link } from 'react-router-dom';
import { LinkNavar } from '../types/LinkNavar';
import { v4 as uuidv4 } from 'uuid';

import Separador from './Separador';

interface LinksConOpcionesProps {
	links: LinkNavar[];
}

const LinkConOpciones = ({ links }: LinksConOpcionesProps) => {
	const [mostrarOpcionesHabilidades, setMostrarOpcionesHabilidades] =
		useState<boolean>(false);

	const desplegarMenuHabilidades = (mostrarOpcionesHabilidades: boolean) => {
		if (mostrarOpcionesHabilidades) {
			return setMostrarOpcionesHabilidades(false);
		} else {
			return setMostrarOpcionesHabilidades(true);
		}
	};

	return (
		<ContenedorCombo
			onMouseLeave={mostrarOpcionesHabilidades => {
				if (mostrarOpcionesHabilidades) {
					return setMostrarOpcionesHabilidades(false);
				} else {
					return setMostrarOpcionesHabilidades(true);
				}
			}}
		>
			<ContenedorBoton
				mostrarOpcionesHabilidades={mostrarOpcionesHabilidades}
				onClick={() => {
					desplegarMenuHabilidades(mostrarOpcionesHabilidades);
				}}
			>
				<span>habilidades</span>
			</ContenedorBoton>
			{mostrarOpcionesHabilidades && (
				<ContenedorLinkDesplegables>
					{links.map((link, index) => {
						return (
							<Fragment key={uuidv4()}>
								<LinkOpciones
									id={`${index}-${link.nombre}`}
									to={link.url}
									onClick={() => {
										desplegarMenuHabilidades(
											mostrarOpcionesHabilidades,
										);
									}}
								>
									{link.nombre}
								</LinkOpciones>
								<Separador />
							</Fragment>
						);
					})}
				</ContenedorLinkDesplegables>
			)}
		</ContenedorCombo>
	);
};

const ContenedorBoton = styled.div<{
	mostrarOpcionesHabilidades: boolean;
}>`
	text-align: center;
	text-transform: uppercase;
	background-color: ${Colores.VIOLETA};
	border: none;
	${props =>
		props.mostrarOpcionesHabilidades
			? `border-radius: 14px 14px 0px 0px;`
			: `border-radius: 14px;`};
	color: ${Colores.BLANCO_PURO};
	font-size: 14px;
	font-weight: 700;
	padding: 6px 10px;
	position: relative;
	width: 100px;
	display: inline-block;
	&:hover,
	&:focus,
	&:active {
		cursor: pointer;
		background-color: ${Colores.VIOLETA_ACTIVO};
	}
`;

const ContenedorLinkDesplegables = styled.div`
	position: absolute;
	text-align: center;
	text-transform: uppercase;
	font-weight: 700;
	height: 70px;
	width: 120px;
	background-color: ${Colores.VIOLETA};
	box-sizing: border-box;
	border-bottom-left-radius: 10px;
	border-bottom-right-radius: 10px;
	padding: 6px 10px;
	outline: none;
	cursor: pointer;
	z-index: 1;
`;

const LinkOpciones = styled(Link)`
	text-decoration: none;
	color: ${Colores.BLANCO_PURO};
	&:hover {
		text-decoration: underline;
	}
`;

const ContenedorCombo = styled.div`
	position: relative;
	flex-direction: column;
	margin-right: ${Grilla.L};
	margin-left: ${Grilla.L};
`;

export default LinkConOpciones;
