import { Route, Routes } from 'react-router-dom';
import HabilidadesABMScreen from '../screens/HabilidadesABMScreen';
import BusquedaUsuarioScreen from '../screens/BusquedaUsuariosScreen';
import BusquedaMasDeseadasScreen from '../screens/BusquedaMasDeseadasSreen';

const RutasDeNavegacion = () => {
	return (
		<Routes>
			<Route
				path="/"
				element={<HabilidadesABMScreen />}
			/>
			<Route
				path="/cargaHabilidades"
				element={<HabilidadesABMScreen />}
			/>
			<Route
				path="/busquedaPerfiles"
				element={<BusquedaUsuarioScreen />}
			/>
			<Route
				path="/busquedaMasDeseadas"
				element={<BusquedaMasDeseadasScreen />}
			/>
		</Routes>
	);
};

export default RutasDeNavegacion;
