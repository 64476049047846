import { Habilidad } from '../types/Habilidad';
import { Interes } from '../types/Interes';
import { Nivel } from '../types/Nivel';
import styled from 'styled-components';
import { Colores, Grilla } from '../constantes/style';
import { v4 as uuidv4 } from 'uuid';
import Tooltip from './Tooltip';
import { useEffect, useRef, useState } from 'react';
import RepuestaDeComponente from '../util/RespuestaComponente';
import { DOWN, ENTER, UP } from '../constantes/teclas';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';

interface ComboCustomProps {
	id: string;
	listado: Habilidad[] | Interes[] | Nivel[];
	titulo: string;
	onChange: (respuesta: RepuestaDeComponente) => void;
	itemSeleccionado?: Habilidad | Interes | Nivel | undefined;
	textoInformativo?: string;
	valorPorParametro?: Habilidad | Interes | Nivel;
}

const ComboCustom = ({
	id,
	listado,
	titulo,
	textoInformativo,
	itemSeleccionado,
	onChange,
	valorPorParametro,
}: ComboCustomProps) => {
	const itemDeLaListaRef = useRef<HTMLLIElement>(null);
	const [scrollActivado, setScrollActivado] = useState<boolean>(false);
	const [mostrarSugerencias, setMostrarSugerencias] = useState<boolean>(false);
	const [indiceItemFocuseado, setItemFocuseado] = useState<number>(0);
	const [valor, setValor] = useState<string | undefined>(
		valorPorParametro?.nombre,
	);

	const seleccionarHandler = (intemFocuseado: Habilidad | Interes | Nivel) => {
		setMostrarSugerencias(false);
		setValor(intemFocuseado.nombre);
		onChange({
			id,
			valor: intemFocuseado,
		} as RepuestaDeComponente);
	};
	const flechasHandler = (event: any) => {
		const indiceFocos = event.key === DOWN ? moverAbajo() : moverArriba();
		setItemFocuseado(indiceFocos);
		event.stopPropagation();
		event.preventDefault();
	};
	const moverAbajo = (): number => {
		if (esElUltimoSeleccionado()) {
			setScrollActivado(false);
			return indiceItemFocuseado;
		}
		const nuevoIndice = indiceItemFocuseado + 1;
		setScrollActivado(nuevoIndice > 4);
		return nuevoIndice;
	};
	const moverArriba = (): number => {
		if (esElPrimerSeleccionado()) {
			setScrollActivado(false);
			return indiceItemFocuseado;
		}
		const nuevoIndice = indiceItemFocuseado - 1;
		setScrollActivado(nuevoIndice < listado.length - 4);
		return nuevoIndice;
	};
	const esElUltimoSeleccionado = () => {
		const ultimoIndice = listado.length - 1;
		return indiceItemFocuseado === ultimoIndice;
	};
	const esElPrimerSeleccionado = () => {
		const primerIndice = 0;
		return indiceItemFocuseado === primerIndice;
	};
	const handlerKeyDown = (event: any) => {
		switch (event.key) {
			case UP:
			case DOWN:
				flechasHandler(event);
				break;
			case ENTER:
				seleccionarHandler(listado[indiceItemFocuseado]);
				break;
		}
	};
	const estaActivo = (index: number) => {
		return indiceItemFocuseado === index;
	};
	useEffect(() => {
		if (itemDeLaListaRef.current !== null && scrollActivado) {
			itemDeLaListaRef.current.scrollIntoView({
				behavior: 'smooth',
				block: 'start',
			});
		}
	}, [indiceItemFocuseado, scrollActivado]);

	useEffect(() => {
		if (!itemSeleccionado) {
			setValor(undefined);
		}
	}, [itemSeleccionado]);

	return (
		<ContenedorCombo
			id={id}
			data-cy={`combo-${id}`}
			onMouseLeave={() => {
				setMostrarSugerencias(false);
			}}
		>
			<div>
				<span>{titulo} </span>
				<Tooltip textoPopUp={textoInformativo} />
			</div>
			<ContenedorPyBoton
				mostrarSugerencias={mostrarSugerencias}
				onKeyDown={handlerKeyDown}
				onClick={() => {
					setMostrarSugerencias(true);
				}}
			>
				<p data-cy={`valor-combo-${id}`}>{valor ? valor : '...'}</p>
				<FontAwesomeIcon icon={faChevronDown} />
			</ContenedorPyBoton>
			<>
				{mostrarSugerencias && (
					<ListaDeSugerencias>
						{listado.map((e, index) => {
							return (
								<ItemLista
									key={uuidv4()}
									id={e.id}
									data-cy={`item-combo-${e.nombre}`}
									estaActivo={estaActivo(index)}
									ref={
										indiceItemFocuseado === index
											? itemDeLaListaRef
											: null
									}
									onClick={event => {
										seleccionarHandler(listado[index]);
										event.stopPropagation();
										event.preventDefault();
									}}
									onMouseOver={() => {
										setItemFocuseado(index);
									}}
								>
									{e.nombre}
								</ItemLista>
							);
						})}
					</ListaDeSugerencias>
				)}
			</>
		</ContenedorCombo>
	);
};

const ItemLista = styled.li<{
	estaActivo: boolean;
}>`
	margin-top: 6px;
	margin-bottom: 6px;
	${props =>
		props.estaActivo
			? `background-color: ${Colores.ROSA_ACTIVO};`
			: `background-color: ${Colores.BLANCO_PURO};`};
`;

const ListaDeSugerencias = styled.ul`
	position: absolute;
	padding-left: 12px;
	padding-right: 12px;
	max-height: 300px;
	width: 150px;
	background-color: ${Colores.BLANCO_PURO};
	box-sizing: border-box;
	border: 1px solid ${Colores.VIOLETA};
	border-top: none;
	border-bottom-left-radius: 10px;
	border-bottom-right-radius: 10px;
	scrollbar-width: auto;
	scrollbar-color: ${Colores.VIOLETA};
	&::-webkit-scrollbar {
		width: 16px;
	}
	&::-webkit-scrollbar-thumb {
		background-color: ${Colores.VIOLETA};
		border-radius: 10px;
		border: 3px solid ${Colores.BLANCO_PURO};
	}
	overflow: auto;
	font-family: Dosis, sans-serif;
	font-size: 16px;
	outline: none;
	cursor: pointer;
	z-index: 1;
`;

const ContenedorCombo = styled.div`
	position: relative;
	flex-direction: column;
	margin-right: ${Grilla.L};
`;

const ContenedorPyBoton = styled.div<{
	mostrarSugerencias: boolean;
}>`
	display: flex;
	align-items: center;
	justify-content: space-between;
	background-color: ${Colores.BLANCO_PURO};
	border: 1px solid ${Colores.VIOLETA} !important;
	border-radius: 14px;
	${props =>
		props.mostrarSugerencias
			? ` border-bottom-left-radius: 0px; border-bottom-right-radius: 0px;`
			: ` border-bottom-left-radius: 14px; border-bottom-right-radius: 14px;`};
	box-sizing: border-box;
	font-family: Dosis, sans-serif;
	font-size: 16px;
	height: 32px;
	margin-top: 6px;
	margin-bottom: 0px;
	padding-left: 10px;
	padding-right: 6px;
	outline: none;
	width: 150px;
`;

export default ComboCustom;
