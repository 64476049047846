import styled from 'styled-components';
import { Colores, Grilla } from '../constantes/style';
import { DetalleHabilidad } from '../types/DetalleHabilidad';
import Boton from './Boton';
import { Interes } from '../types/Interes';
import { Nivel } from '../types/Nivel';
import Separador from './Separador';
import ComboCustom from './ComboCustom';
import RepuestaDeComponente from '../util/RespuestaComponente';
import TextArea from './TextArea';
import { TOOLTIP_INTERES, TOOLTIP_NIVEL } from '../constantes/combos';

interface EditorHabilidadesProps {
	detalleHabilidad: DetalleHabilidad;
	onCancelar: () => void;
	onAceptar: () => void;
	onChange: (respuesta: RepuestaDeComponente) => void;
	onObservacionesChage: (
		evento: React.ChangeEvent<HTMLTextAreaElement>,
	) => void;
	niveles: Nivel[];
	intereses: Interes[];
}

const EditorHabilidades = ({
	detalleHabilidad,
	niveles = [],
	intereses = [],
	onCancelar,
	onAceptar,
	onChange,
	onObservacionesChage,
}: EditorHabilidadesProps) => {
	return (
		<ContenedorEditor>
			<Titulo
				data-cy={`modal-edicion-titulo`}
			>{`${detalleHabilidad.habilidad.nombre}`}</Titulo>
			<Separador altura={Grilla.M} />
			<ContenedorComponentes>
				<ComboCustom
					id={'niveles editor'}
					listado={niveles}
					titulo={'Selecciona tu nivel:'}
					onChange={onChange}
					valorPorParametro={detalleHabilidad.nivel}
					textoInformativo={TOOLTIP_NIVEL}
					itemSeleccionado={detalleHabilidad.nivel}
				/>
				<ComboCustom
					id={'intereses editor'}
					listado={intereses}
					titulo={'Nivel de interes:'}
					onChange={onChange}
					valorPorParametro={detalleHabilidad.interes}
					textoInformativo={TOOLTIP_INTERES}
					itemSeleccionado={detalleHabilidad.interes}
				/>
			</ContenedorComponentes>
			<Separador altura={Grilla.M} />
			<Titulo>{`Observacion`}</Titulo>
			<Separador altura={Grilla.M} />
			<TextArea
				id={'Observaciones editor'}
				limiteCaracteres={140}
				onObservacionesChage={onObservacionesChage}
				valorParam={detalleHabilidad.observaciones}
				placeHolder={'Observaciones'}
			/>
			<Separador altura={Grilla.M} />
			<ContenedorComponentes>
				<BotonEditor>
					<Boton
						texto={'aceptar'}
						onSubmit={onAceptar}
						dataCy={'modal-boton-aceptar'}
					/>
				</BotonEditor>
				<BotonEditor>
					<Boton
						texto={'cancelar'}
						onSubmit={onCancelar}
						color={Colores.ROSA}
						activColor={Colores.ROSA_ACTIVO}
						dataCy={'modal-boton-cancelar'}
					/>
				</BotonEditor>
			</ContenedorComponentes>
		</ContenedorEditor>
	);
};

const Titulo = styled.h2`
	margin: 0;
`;

const BotonEditor = styled.div`
	margin-right: ${Grilla.L};
`;

const ContenedorEditor = styled.div`
	box-sizing: border-box;
	display: flex;
	flex-direction: column;
	height: auto;
	padding: 26px 35px;
	position: relative;
	max-height: 80vh;
	width: 1000px;
`;

const ContenedorComponentes = styled.div`
	display: flex;
	width: 100%;
`;

export default EditorHabilidades;
