import { useState } from 'react';
import { Habilidad } from '../types/Habilidad';
import clienteApi from '../util/clienteApi';

export const useHabilidades = () => {
	const [cargandoHabilidades, setCargandoHabilidades] =
		useState<boolean>(false);

	const [errorConsultaHabilidad, setErrorConsultaHabilidad] =
		useState<boolean>(false);

	let mensajeDeErrorHabilidades: string = '';

	const listarHabilidades = async (): Promise<Habilidad[]> => {
		setCargandoHabilidades(true);

		let listaDeHabilidades: Habilidad[] = [];
		try {
			const response = await clienteApi.get('/habilidades');
			listaDeHabilidades = response.data;
		} catch (e: any) {
			setErrorConsultaHabilidad(true);
			mensajeDeErrorHabilidades = e.message;
		} finally {
			setCargandoHabilidades(false);
			return listaDeHabilidades;
		}
	};
	return {
		listarHabilidades,
		setErrorConsultaHabilidad,
		cargandoHabilidades,
		errorConsultaHabilidad,
		mensajeDeErrorHabilidades,
	};
};
