import { useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import Header from './components/Header';
import LoginView from './views/LoginView';
import styled from 'styled-components';
import RutasDeNavegacion from './components/RutasDeNavegacion';
import LinkConOpciones from './components/LinkConOpciones';
import { useUsuario } from './hooks/useUsuario';
import { useAuthUser } from './hooks/useAuthUser';
import { getAuth } from 'firebase/auth';
import { LinkNavar } from './types/LinkNavar';
import { Colores } from './constantes/style';
import { Link } from 'react-router-dom';

const App = () => {
	const { user, login, logout } = useAuthUser();
	const { currentUser } = getAuth();

	const { crearUsuario, creandoUsuario, errorCreandoUsuario } = useUsuario();

	const cargarUsuario = async (
		idUsuario: string | null | undefined,
		nombre: string | null | undefined,
		avatar: string | null | undefined,
	) => {
		await crearUsuario(idUsuario, nombre, avatar);
	};

	const linksEnHabilidades: LinkNavar[] = [
		{ url: '/cargaHabilidades', nombre: 'Cargar' },
		{ url: '/busquedaMasDeseadas', nombre: 'Ranking' },
	];

	useEffect(() => {
		if (currentUser !== null) {
			cargarUsuario(user?.uid, user?.displayName, user?.photoURL);
		}
	}, [user]);
	return (
		<SuperContenedor>
			<BrowserRouter>
				<Header
					nombreDeUsuario={currentUser?.displayName}
					avatar={currentUser?.photoURL}
					onCerrarSesion={logout}
				>
					<ContenedorBotonBusqueda>
						<LinkOpciones to="/busquedaPerfiles">
							Busqueda de perfiles
						</LinkOpciones>
					</ContenedorBotonBusqueda>
					<LinkConOpciones links={linksEnHabilidades} />
				</Header>
				{!currentUser && <LoginView login={login} />}
				{!!currentUser && <RutasDeNavegacion />}
			</BrowserRouter>
		</SuperContenedor>
	);
};

const SuperContenedor = styled.div`
	display: flex;
	align-items: center;
	flex-direction: column;
`;

const ContenedorBotonBusqueda = styled.div`
	font-family: 'Dosis', sans-serif;
	font-weight: 700;
	font-size: 14px;
	text-transform: uppercase;
	text-align: center;
	background-color: ${Colores.VIOLETA};
	border: none;
	border-radius: 14px;
	padding: 6px 10px;
	width: 150px;
	height: 15px;
	&:hover,
	&:focus,
	&:active {
		background-color: ${Colores.VIOLETA_ACTIVO};
		cursor: pointer;
	}
`;

const LinkOpciones = styled(Link)`
	text-decoration: none;
	color: ${Colores.BLANCO_PURO};
	&:hover {
		text-decoration: underline;
	}
`;

export default App;
