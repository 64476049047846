export const NIVELES = [
	{ id: '1', nombre: 'Bajo' },
	{ id: '2', nombre: 'Intermedio' },
	{ id: '3', nombre: 'Alto' },
];

export const INTERESES = [
	{ id: '1', nombre: 'Bajo' },
	{ id: '2', nombre: 'Intermedio' },
	{ id: '3', nombre: 'Alto' },
];

export const TOOLTIP_NIVEL =
	'seleccioná tu nivel de conocimiento con la habilidad/tecnología seleccionada';
export const TOOLTIP_INTERES =
	'seleccioná tu nivel de interés en desarrollar con la habilidad/tecnología seleccionada';
