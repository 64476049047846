import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Colores } from '../constantes/style';

interface TextAreaProps {
	id: string;
	limiteCaracteres: number;
	valorParam?: string | undefined;
	placeHolder?: string;
	onObservacionesChage: (
		evento: React.ChangeEvent<HTMLTextAreaElement>,
	) => void;
}

const TextArea = ({
	id,
	limiteCaracteres,
	valorParam = '',
	placeHolder,
	onObservacionesChage,
}: TextAreaProps) => {
	const [cracteresDisponibles, setCracteresDisponibles] =
		useState<number>(limiteCaracteres);
	const [valor, setValor] = useState<string>(valorParam);

	useEffect(() => {
		setCracteresDisponibles(limiteCaracteres - valor.length);
	});
	return (
		<Contenedor>
			<TextAreaPersonalizado
				caracteresRestantes={cracteresDisponibles}
				maxLength={limiteCaracteres}
				id={id}
				onChange={(evento: React.ChangeEvent<HTMLTextAreaElement>) => {
					setValor(evento.target.value);
					onObservacionesChage(evento);
				}}
				placeholder={placeHolder}
				value={valor}
				data-cy={`${id}`}
			/>
			<Contador>{`${cracteresDisponibles}/${limiteCaracteres}`}</Contador>
		</Contenedor>
	);
};

const Contador = styled.span`
	margin-top: 5px;
`;

const Contenedor = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	margin-top: 15px;
	margin-bottom: 15px;
	height: auto;
	width: 100%;
`;

const TextAreaPersonalizado = styled.textarea<{
	caracteresRestantes: number;
}>`
	border: 1px solid ${Colores.VIOLETA};
	border-radius: 14px;
	box-sizing: border-box;
	resize: none;
	padding: 10px;
	font-family: inherit;
	font-size: inherit;
	${props => {
		if (props.caracteresRestantes === 0) {
			return `color: ${Colores.ROSA}`;
		}
		return ` color: black`;
	}};
	min-height: 90px;
	height: auto;
	width: 100%;
`;

export default TextArea;
