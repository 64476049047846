import { useEffect, useState } from 'react';
import { useHabilidadesMasDeseadas } from '../hooks/useHabilidadesMasDeseadas';
import BusquedaMasDeseadasView from '../views/BusquedaMasDeseadasView';
import { SpinnerBlanco } from '../components/SpinnerBlanco';

const BusquedaMasDeseadasScreen = () => {
	const [listadoHabilidadesNivel, setListadoHabilidadesNivel] = useState<
		string[]
	>([]);

	const [listadoHabilidadesInteres, setListadoHabilidadesInteres] = useState<
		string[]
	>([]);
	const [habiliadesPorMayorConocimiento, setHabiliadesPorMayorConocimiento] =
		useState<boolean>(true);

	const [habiliadesPorMayorInteres, setHabiliadesPorMayorInteres] =
		useState<boolean>(true);

	const {
		listarHabilidadesMasDeseadas,
		setErrorBuscandoHabilidadesMasDeseadas,
		errorBuscandoHabilidadesMasDeseadas,
		obtenerArchivoConListaDeHabilidades,
		errorDescargandoArchivo,
	} = useHabilidadesMasDeseadas();

	const cargarDatos = async () => {
		const habilidadesDesdeElBack: string[][] =
			await listarHabilidadesMasDeseadas();
		let nivel: string[] = [];
		let interes: string[] = [];
		for (let index = 0; index < habilidadesDesdeElBack[1].length; index++) {
			nivel.push(habilidadesDesdeElBack[0][index]);
			interes.push(habilidadesDesdeElBack[1][index]);
		}
		setListadoHabilidadesNivel(nivel);
		setListadoHabilidadesInteres(interes);
	};

	const cerrarModal = () => {
		setErrorBuscandoHabilidadesMasDeseadas(false);
	};

	const onCambiarSentidoDeHabilidades = (porConocimiento: boolean = true) => {
		if (porConocimiento) {
			setHabiliadesPorMayorConocimiento(!habiliadesPorMayorConocimiento);
			setListadoHabilidadesNivel(listadoHabilidadesNivel.reverse());
		} else {
			setHabiliadesPorMayorInteres(!habiliadesPorMayorInteres);
			setListadoHabilidadesInteres(listadoHabilidadesInteres.reverse());
		}
	};

	const seCargaronLasListas = () => {
		return (
			listadoHabilidadesNivel.length > 0 ||
			listadoHabilidadesInteres.length > 0
		);
	};

	useEffect(() => {
		cargarDatos();
	}, []);

	return (
		<>
			{seCargaronLasListas() ? (
				<BusquedaMasDeseadasView
					listadoNivel={listadoHabilidadesNivel}
					listadoInteres={listadoHabilidadesInteres}
					error={
						errorBuscandoHabilidadesMasDeseadas && errorDescargandoArchivo
					}
					mensajeDeError={
						'Ups ocurrio un error, vamos a estar trabajando en esto ;)'
					}
					onCerrarModal={cerrarModal}
					habiliadesPorMayorConocimiento={habiliadesPorMayorConocimiento}
					habiliadesPorMayorInteres={habiliadesPorMayorInteres}
					onCambiarSentidoHabilidades={onCambiarSentidoDeHabilidades}
					descargarArchivo={obtenerArchivoConListaDeHabilidades}
				/>
			) : (
				<SpinnerBlanco />
			)}
		</>
	);
};
export default BusquedaMasDeseadasScreen;
