import { useCallback, useEffect, useState } from 'react';
import { Habilidad } from '../types/Habilidad';
import HabilidadesABMView from '../views/HabilidadesABMView';
import { useHabilidades } from '../hooks/useHabilidades';
import { useDetalleHabilidad } from '../hooks/useDetalleHabilidad';
import { DetalleHabilidad } from '../types/DetalleHabilidad';
import { v4 as uuidv4 } from 'uuid';
import { NIVELES, INTERESES } from '../constantes/combos';
import RepuestaDeComponente from '../util/RespuestaComponente';
import { Nivel } from '../types/Nivel';
import { Interes } from '../types/Interes';
import { SpinnerBlanco } from '../components/SpinnerBlanco';

const HabilidadesABMScreen = () => {
	const niveles = NIVELES;
	const intereses = INTERESES;
	const [habilidades, setHabilidades] = useState<Habilidad[]>([]);

	const [habilidadSeleccionada, setHabilidadSeleccionada] = useState<
		Habilidad | undefined
	>(undefined);
	const [nivelSeleccionado, setNivelSeleccionado] = useState<
		Nivel | undefined
	>(undefined);
	const [interesSeleccionado, setInteresSeleccionado] = useState<
		Interes | undefined
	>(undefined);
	const [detalleHabilidades, setDetalleHabilidades] = useState<
		DetalleHabilidad[]
	>([]);
	const [habilidadEnEdicion, setHabilidadEnEdicion] =
		useState<DetalleHabilidad>({} as DetalleHabilidad);

	const [observaciones, setObservaciones] = useState<string>();
	const [seleccionValida, setSeleccionValida] = useState<boolean>(false);
	const [debeMostrarModalDeEdicion, setDebeMostrarModalDeEdicion] =
		useState(false);

	const {
		agregarDetalleHabilidad,
		agregandoDetalleHabilidad,
		eliminarDetalleHabilidad,
		listarDetalleHabilidadPorIdDeUsuario,
		actualizarDetalleHabilidad,
		cargandoDetalleHabilidad,
		errorDetalleHabilidad,
		mensajeDeError,
		setErrorDetalleHabilidad,
	} = useDetalleHabilidad();
	const {
		listarHabilidades,
		cargandoHabilidades,
		errorConsultaHabilidad,
		setErrorConsultaHabilidad,
	} = useHabilidades();

	const resetearEstadoDeLaPantall = () => {
		setHabilidadSeleccionada(undefined);
		setNivelSeleccionado(undefined);
		setInteresSeleccionado(undefined);
		setObservaciones(undefined);
	};

	const cargarDatosInicialesPagina = async () => {
		await refrescarDetalleHabilidad();
		await refrescarHabilidades();
	};

	const refrescarDetalleHabilidad = async () => {
		const detalleHabilidadDelUsuario: DetalleHabilidad[] =
			await listarDetalleHabilidadPorIdDeUsuario();
		setDetalleHabilidades(detalleHabilidadDelUsuario);
	};

	const refrescarHabilidades = async () => {
		setHabilidades([]);
		const habilidadesDesdeElBack: Habilidad[] = await listarHabilidades();
		setHabilidades(habilidadesDesdeElBack);
	};

	const cerrarModal = () => {
		setearErroresEnFalse();
		resetearEstadoDeLaPantall();
	};

	const setearErroresEnFalse = () => {
		setErrorDetalleHabilidad(false);
		setErrorConsultaHabilidad(false);
	};

	const onEliminarDetalleHabilidad = async (id: string) => {
		await eliminarDetalleHabilidad(id);
		await refrescarDetalleHabilidad();
	};

	const onInputChange = useCallback(
		(respuesta: RepuestaDeComponente) => {
			switch (respuesta.id) {
				case 'niveles':
					setNivelSeleccionado(respuesta.valor);
					break;
				case 'habilidades':
					setHabilidadSeleccionada(respuesta.valor);
					break;
				case 'intereses':
					setInteresSeleccionado(respuesta.valor);
					break;
				case 'intereses editor':
					if (respuesta.valor) {
						habilidadEnEdicion.interes = respuesta.valor;
						setHabilidadEnEdicion(habilidadEnEdicion);
					}
					break;
				case 'niveles editor':
					if (respuesta.valor) {
						habilidadEnEdicion.nivel = respuesta.valor;
						setHabilidadEnEdicion(habilidadEnEdicion);
					}
					break;
			}
		},
		[
			nivelSeleccionado,
			habilidadSeleccionada,
			interesSeleccionado,
			habilidadEnEdicion,
		],
	);

	const onObservacionesChange = useCallback(
		(evento: React.ChangeEvent<HTMLTextAreaElement>) => {
			const valor = evento.target.value;
			switch (evento.target.id) {
				case 'Observaciones':
					setObservaciones(evento.target.value);
					break;
				case 'Observaciones editor':
					habilidadEnEdicion.observaciones = valor;
					break;
			}
		},
		[observaciones, habilidadEnEdicion],
	);

	const onSubmit = useCallback(async () => {
		if (habilidadSeleccionada && nivelSeleccionado && interesSeleccionado) {
			const detalleHabilidad = {
				id: uuidv4(),
				habilidad: habilidadSeleccionada,
				nivel: nivelSeleccionado,
				interes: interesSeleccionado,
				observaciones,
			};
			await agregarDetalleHabilidad(detalleHabilidad);
			const nuevasHabilidadesDelUsuario =
				await listarDetalleHabilidadPorIdDeUsuario();
			setDetalleHabilidades(nuevasHabilidadesDelUsuario);
			resetearEstadoDeLaPantall();
			await refrescarDetalleHabilidad();
			await refrescarHabilidades();
		}
	}, [
		habilidadSeleccionada,
		nivelSeleccionado,
		interesSeleccionado,
		observaciones,
		agregarDetalleHabilidad,
		listarDetalleHabilidadPorIdDeUsuario,
		refrescarDetalleHabilidad,
	]);

	const onSubmitEditor = useCallback(async () => {
		await actualizarDetalleHabilidad(habilidadEnEdicion);
		await refrescarDetalleHabilidad();
	}, [habilidadEnEdicion]);

	const seCargaronLasHabilidadesODetalleHabilidades = () => {
		return habilidades.length != 0;
	};

	useEffect(() => {
		cargarDatosInicialesPagina();
	}, []);

	useEffect(() => {
		setSeleccionValida(
			habilidadSeleccionada !== undefined &&
				nivelSeleccionado !== undefined &&
				interesSeleccionado !== undefined,
		);
	}, [habilidadSeleccionada, nivelSeleccionado, interesSeleccionado]);

	return (
		<>
			{seCargaronLasHabilidadesODetalleHabilidades() ? (
				<HabilidadesABMView
					habilidadSeleccionada={habilidadSeleccionada}
					intereSeleccionada={interesSeleccionado}
					nivelSeleccionada={nivelSeleccionado}
					habilidades={habilidades}
					niveles={niveles}
					intereses={intereses}
					habilidadEnEdicion={habilidadEnEdicion}
					setHabilidadEnEdicion={setHabilidadEnEdicion}
					setDebeMostrarModalDeEdicion={setDebeMostrarModalDeEdicion}
					debeMostrarModalDeEdicion={debeMostrarModalDeEdicion}
					detalleHabilidades={detalleHabilidades}
					error={errorConsultaHabilidad || errorDetalleHabilidad}
					mensajeDeError={mensajeDeError}
					seleccionValida={seleccionValida}
					agregandoDetalleHabilidad={agregandoDetalleHabilidad}
					cargandoDetalleHabilidad={cargandoDetalleHabilidad}
					onSubmit={onSubmit}
					onInputChange={onInputChange}
					onSubmitEditor={onSubmitEditor}
					onCerrarModal={cerrarModal}
					onEliminarDetalleHabilidad={onEliminarDetalleHabilidad}
					onObservacionesChange={onObservacionesChange}
				/>
			) : (
				<SpinnerBlanco />
			)}
		</>
	);
};

export default HabilidadesABMScreen;
