import styled from 'styled-components';
import { Colores, Grilla } from '../constantes/style';
import { DetalleHabilidad } from '../types/DetalleHabilidad';
import { Usuario } from '../types/Usuario';
import ResultadoHabilidadesDeUsuario from './ResultadoHabilidadesDeUsuario';
import { v4 as uuidv4 } from 'uuid';
interface ListaProps {
	listado: Usuario[];
}

const Lista = ({ listado }: ListaProps) => {
	const existeObservacion = (detalleHabilidades: DetalleHabilidad[]) => {
		let hayObservacion = false;
		detalleHabilidades.forEach(d => {
			if (d.observaciones) {
				hayObservacion = true;
			}
		});
		return hayObservacion;
	};

	return (
		<ListaPersonalizada>
			{listado.map((e, i) => {
				const autoKey = uuidv4();
				return (
					<ResultadoHabilidadesDeUsuario
						key={autoKey}
						nombre={e.nombre}
						avatarUrl={e.avatar}
						fechaUltimaActualizacion={e.fechaUltimaActualizacion}
						detalleHabilidades={e.detalleHabilidades}
						masInformacion={() => existeObservacion(e.detalleHabilidades)}
					/>
				);
			})}
		</ListaPersonalizada>
	);
};

const ListaPersonalizada = styled.div`
	width: 100%;
	margin-top: ${Grilla.M};
	scrollbar-width: auto;
	scrollbar-color: ${Colores.VIOLETA} ${Colores.BLANCO_PURO};
	&::-webkit-scrollbar {
		width: 16px;
	}
	&::-webkit-scrollbar-track {
		background: ${Colores.BLANCO_PURO};
	}
	&::-webkit-scrollbar-thumb {
		background-color: ${Colores.VIOLETA};
		border-radius: 10px;
		border: 3px solid ${Colores.BLANCO_PURO};
	}
	overflow: auto;
`;

export default Lista;
