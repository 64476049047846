import styled from 'styled-components';
import { Colores } from '../constantes/style';
import { Habilidad } from '../types/Habilidad';
import InfoHabilidad from './InfoHabilidad';

interface ContenedorDeFiltrosHabilidadProps {
	habilidadesABuscar: Habilidad[];
	onEliminarFiltroHabilidad: (idHabilidad: string) => void;
}

const ContenedorDeFiltrosHabilidad = ({
	habilidadesABuscar,
	onEliminarFiltroHabilidad,
}: ContenedorDeFiltrosHabilidadProps) => {
	return (
		<BarraDeFiltros>
			{habilidadesABuscar.map(e => (
				<InfoHabilidad
					key={e.id}
					texto={e.nombre}
					onEliminarFiltroHabilidad={() => onEliminarFiltroHabilidad(e.id)}
				/>
			))}
		</BarraDeFiltros>
	);
};

const BarraDeFiltros = styled.div`
	align-items: center;
	flex-direction: row;
	background-color: ${Colores.BLANCO_PURO};
	display: flex;
	height: auto;
	position: absolute;
	width: 76%;
	border: 1px solid ${Colores.VIOLETA};
	border-radius: 14px;
	box-sizing: border-box;
	padding: 10px;
	min-height: 56px;
	flex-wrap: wrap;
	right: 0;
`;

export default ContenedorDeFiltrosHabilidad;
