export enum Grilla {
	XS = '5px',
	S = '10px',
	SS = '14px',
	M = '20px',
	L = '30px',
	XL = '40px',
	XXL = '60px',
	XXXL = '150px',
}

export enum Colores {
	VIOLETA = '#8e3bff',
	VIOLETA_ACTIVO = '#b160ff',
	BLANCO_PURO = '#ffffff',
	ROSA = '#f941a3',
	ROSA_ACTIVO = '#fa5aaf',
	NEGRO = '#000000',
	GRIS_ACTIVO = '#5B5554 ',
}
