import { useState } from 'react';
import clienteApi from '../util/clienteApi';

export const useUsuario = () => {
	const [creandoUsuario, setCreandoUsuario] = useState<boolean>(false);

	const [errorCreandoUsuario, setErrorCreandoUsuario] =
		useState<boolean>(false);

	const crearUsuario = async (
		idUsuario: string | null | undefined,
		nombre: string | null | undefined,
		avatar: string | null | undefined,
	) => {
		setCreandoUsuario(true);
		try {
			await clienteApi.put('/usuarios', { idUsuario, nombre, avatar });
		} catch (e) {
			setErrorCreandoUsuario(true);
		} finally {
			setErrorCreandoUsuario(false);
			setCreandoUsuario(false);
		}
	};
	return {
		crearUsuario,
		creandoUsuario,
		errorCreandoUsuario,
	};
};
