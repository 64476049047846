import styled from 'styled-components';
import { Colores } from '../constantes/style';
import { Link } from 'react-router-dom';
import Logo from '../img/logo-esfera-blanco.png';
import Imagen from './Imagen';
import MenuKebab from './MenuKebab';

interface HeaderProps {
	nombreDeUsuario?: string | null | undefined;
	avatar?: string | null | undefined;
	children: React.ReactNode;
	onCerrarSesion: () => void;
}

const Header = ({
	nombreDeUsuario,
	avatar,
	children,
	onCerrarSesion,
}: HeaderProps) => {
	return (
		<NavPersonalizado>
			<ContenedorLinks>
				<Link to="/cargaHabilidades">
					<Imagen
						src={Logo}
						alt={'logo grupo esfera'}
					/>
				</Link>
			</ContenedorLinks>
			<ContenedorBotonesYAvatarUsuario>
				{!!nombreDeUsuario && (
					<ContenedorBotonesNavar>{children}</ContenedorBotonesNavar>
				)}
				<ContenedorAvatarUsuario>
					<span>{`Holi, ${
						nombreDeUsuario ? nombreDeUsuario + '!' : 'perri'
					}`}</span>
					{!!avatar && <Avatar src={avatar} />}
				</ContenedorAvatarUsuario>
				{nombreDeUsuario && <MenuKebab onCerrarSesion={onCerrarSesion} />}
			</ContenedorBotonesYAvatarUsuario>
		</NavPersonalizado>
	);
};

const Avatar = styled.img`
	border-radius: 50%;
	width: 28px;
	height: 28px;
	margin: 0 10px;
`;

const NavPersonalizado = styled.nav`
	padding: 30px;
	margin-bottom: 30px;
	box-sizing: border-box;
	height: 60px;
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
	background-color: ${Colores.NEGRO};
`;

const ContenedorLinks = styled.div`
	display: flex;
	flex-wrap: wrap;
`;

const ContenedorBotonesYAvatarUsuario = styled.div`
	display: flex;
	flex-direction: row !important;
`;

const ContenedorBotonesNavar = styled.div`
	display: flex;
	flex-direction: row !important;
	width: 600px;
	align-items: center;
	justify-content: flex-end;
`;

const ContenedorAvatarUsuario = styled.div`
	align-items: center;
	color: ${Colores.BLANCO_PURO};
	display: flex;
	margin-left: 10px;
	font-weight: 500;
`;

export default Header;
