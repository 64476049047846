import styled from 'styled-components';
import { Grilla, Colores } from '../constantes/style';
import { faTrash, faPen } from '@fortawesome/free-solid-svg-icons';
import { v4 as uuidv4 } from 'uuid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DetalleHabilidad } from '../types/DetalleHabilidad';
import Modal from './Modal';
import { Interes } from '../types/Interes';
import { Nivel } from '../types/Nivel';
import EditorHabilidades from './EditorDeHabilidades';
import RepuestaDeComponente from '../util/RespuestaComponente';

interface TablaDetalleHabilidadesProps {
	detalleHabilidades: DetalleHabilidad[];
	width?: string;
	onEliminarDetalleHabilidad?: (id: string) => void;
	onInputChange?: (respuesta: RepuestaDeComponente) => void;
	onObservacionesChangeEditor?: (
		evento: React.ChangeEvent<HTMLTextAreaElement>,
	) => void;
	onSubmitEditor?: () => void;
	setHabilidadEnEdicion?: (edicion: DetalleHabilidad) => void;
	botonEdicionHabilitado?: boolean;
	niveles?: Nivel[];
	intereses?: Interes[];
	habilidadEnEdicion?: DetalleHabilidad;
	debeMostrarModalDeEdicion?: boolean;
	setDebeMostrarModalDeEdicion?: (val: boolean) => void;
}

const TablaDetalleHabilidades = ({
	detalleHabilidades,
	width,
	onEliminarDetalleHabilidad,
	onInputChange = (respuesta: RepuestaDeComponente) => {},
	onObservacionesChangeEditor = (
		evento: React.ChangeEvent<HTMLTextAreaElement>,
	) => {},
	onSubmitEditor = () => {},
	setHabilidadEnEdicion = () => {},
	setDebeMostrarModalDeEdicion = (val: boolean) => {},
	botonEdicionHabilitado = false,
	niveles = [],
	intereses = [],
	habilidadEnEdicion = {} as DetalleHabilidad,
	debeMostrarModalDeEdicion = false,
}: TablaDetalleHabilidadesProps) => {
	return (
		<ContenedorTabla width={width}>
			<ContenedorDeContenidoDeFilaDeTitulos>
				<HeadingPersonalizado>Habilidad</HeadingPersonalizado>
				<HeadingPersonalizado>Nivel</HeadingPersonalizado>
				<HeadingPersonalizado>Interes</HeadingPersonalizado>
				<HeadingPersonalizado ensanchar>Observaciones</HeadingPersonalizado>
			</ContenedorDeContenidoDeFilaDeTitulos>
			<ContenedorDeFilas>
				{detalleHabilidades.map((detalleHabilidad, index) => {
					return (
						<FilaDeTabla
							key={uuidv4()}
							data-cy={`item-detalle-habilidad`}
						>
							<ContenedorDeContenidoDeFila>
								<SpanPersonalizado
									data-cy={`item-detalle-habilidad-${detalleHabilidad.habilidad.nombre}`}
								>
									{detalleHabilidad.habilidad.nombre}
								</SpanPersonalizado>
								<SpanPersonalizado
									data-cy={`item-detalle-nivel-${detalleHabilidad.nivel.nombre}-${detalleHabilidad.habilidad.nombre}`}
								>
									{detalleHabilidad.nivel.nombre}
								</SpanPersonalizado>
								<SpanPersonalizado
									data-cy={`item-detalle-interes-${detalleHabilidad.interes.nombre}-${detalleHabilidad.habilidad.nombre}`}
								>
									{detalleHabilidad.interes.nombre}
								</SpanPersonalizado>
								<SpanPersonalizado
									ensanchar
									data-cy={`item-detalle-observacion-${detalleHabilidad.habilidad.nombre}`}
								>
									{detalleHabilidad.observaciones}
								</SpanPersonalizado>
								<ContenedorBotones>
									{botonEdicionHabilitado && (
										<BotonTabla
											data-cy={`item-detalle-edicion-${detalleHabilidad.habilidad.nombre}`}
											onClick={() => {
												setDebeMostrarModalDeEdicion(true);
												const detalleHabilidadAEditar: DetalleHabilidad =
													{ ...detalleHabilidades[index] };
												setHabilidadEnEdicion(
													detalleHabilidadAEditar,
												);
											}}
										>
											<FontAwesomeIcon icon={faPen} />
										</BotonTabla>
									)}
									{onEliminarDetalleHabilidad && (
										<BotonTabla
											data-cy={`boton-eleiminar-${detalleHabilidad.habilidad.nombre}`}
											onClick={() => {
												return onEliminarDetalleHabilidad(
													detalleHabilidad.id,
												);
											}}
										>
											<FontAwesomeIcon icon={faTrash} />
										</BotonTabla>
									)}
								</ContenedorBotones>
							</ContenedorDeContenidoDeFila>
						</FilaDeTabla>
					);
				})}
			</ContenedorDeFilas>
			{debeMostrarModalDeEdicion && (
				<Modal
					titulo={`Editando Habilidad`}
					children={
						<EditorHabilidades
							detalleHabilidad={habilidadEnEdicion}
							onCancelar={() => {
								setDebeMostrarModalDeEdicion(false);
							}}
							onChange={onInputChange}
							onObservacionesChage={onObservacionesChangeEditor}
							onAceptar={() => {
								onSubmitEditor();
								setDebeMostrarModalDeEdicion(false);
							}}
							niveles={niveles}
							intereses={intereses}
						/>
					}
					cerrarModal={() => setDebeMostrarModalDeEdicion(false)}
				/>
			)}
		</ContenedorTabla>
	);
};

const ContenedorTabla = styled.div<{ width?: string }>`
	height: auto;
	overflow: clipped;
	${props => (props.width ? ` width: ${props.width}; ` : ` width: 100%; `)}
`;

const ContenedorDeFilas = styled.ul`
	width: 100%;
	max-height: 30vh;
	height: auto;
	scrollbar-width: auto;
	scrollbar-color: ${Colores.VIOLETA} ${Colores.BLANCO_PURO};
	&::-webkit-scrollbar {
		width: 16px;
	}
	&::-webkit-scrollbar-track {
		background: ${Colores.BLANCO_PURO};
	}
	&::-webkit-scrollbar-thumb {
		background-color: ${Colores.VIOLETA};
		border-radius: 10px;
		border: 3px solid ${Colores.BLANCO_PURO};
	}
	overflow-y: overlay;
	overflow-x: auto;
`;

const FilaDeTabla = styled.li`
	border-bottom: 1px solid ${Colores.VIOLETA};
	padding: ${Grilla.S} 0;
	position: relative;
	margin-right: ${Grilla.M};
`;

const ContenedorDeContenidoDeFila = styled.div`
	display: flex;
`;

const ContenedorDeContenidoDeFilaDeTitulos = styled(
	ContenedorDeContenidoDeFila,
)`
	padding-right: 20px;
	box-sizing: border-box;
`;

const SpanPersonalizado = styled.div<{ ensanchar?: boolean }>`
	${props => (props.ensanchar ? ` width: 45%; ` : ` width: 15%; `)}
	${props => props.ensanchar && ` word-wrap: break-word;`}
`;

const BotonTabla = styled.button`
	border: none;
	background-color: transparent;
	cursor: pointer;
	color: ${Colores.VIOLETA};
`;

const ContenedorBotones = styled.div`
	display: flex;
	right: 0;
	position: absolute;
	top: 12px;
`;

const HeadingPersonalizado = styled.h4<{ ensanchar?: boolean }>`
	${props => (props.ensanchar ? ` width: 45%; ` : ` width: 15%; `)}
	margin-bottom: 0;
`;

export default TablaDetalleHabilidades;
