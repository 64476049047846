import { Habilidad } from '../types/Habilidad';
import { Interes } from '../types/Interes';
import { Nivel } from '../types/Nivel';
import styled from 'styled-components';
import { Colores, Grilla } from '../constantes/style';
import { v4 as uuidv4 } from 'uuid';
import Tooltip from './Tooltip';
import { useEffect, useRef, useState } from 'react';
import RepuestaDeComponente from '../util/RespuestaComponente';
import { DOWN, ENTER, UP } from '../constantes/teclas';

interface InputConSugerenciasProps {
	id: string;
	listado: Habilidad[] | Interes[] | Nivel[];
	titulo: string;
	itemSeleccionado: Habilidad | Interes | Nivel | undefined;
	onChange: (respuesta: RepuestaDeComponente) => void;
	textoInformativo?: string;
}

const InputConSugerencias = ({
	id,
	listado,
	titulo,
	itemSeleccionado,
	textoInformativo,
	onChange,
}: InputConSugerenciasProps) => {
	const itemDeLaListaRef = useRef<HTMLLIElement>(null);
	const [listadoConSugerencias, setListarConSugerencias] = useState<
		Habilidad[] | Interes[] | Nivel[]
	>([]);
	const [mouseSobreDesplegable, setMouseSobreDesplegable] =
		useState<boolean>(false);
	const [scrollActivado, setScrollActivado] = useState<boolean>(false);
	const [mostrarSugerencias, setMostrarSugerencias] = useState<boolean>(false);
	const [indiceItemFocuseado, setItemFocuseado] = useState<number>(0);
	const [valor, setValor] = useState<string>('');

	const onChangeInput = (event: any) => {
		setValor(event.target.value);
		if (event.target.value.length === 0) {
			setListarConSugerencias([]);
		} else {
			let listadoFiltrado = listado.filter(e =>
				e.nombre
					.toLowerCase()
					.trim()
					.includes(event.target.value.toLowerCase().trim()),
			);
			setListarConSugerencias(
				listadoFiltrado.length !== 0
					? listadoFiltrado
					: [
							{
								id: '0',
								nombre: event.target.value.toLowerCase().trim(),
								interesGeneral: 0,
								nivelGeneral: 0,
							},
					  ],
			);
		}
	};
	const seleccionarHandler = (intemFocuseado: Habilidad | Interes | Nivel) => {
		setListarConSugerencias([]);
		onChange({
			id,
			valor: intemFocuseado,
		} as RepuestaDeComponente);
		setValor(intemFocuseado.nombre);
	};
	const flechasHandler = (event: any) => {
		const indiceFocos = event.key === DOWN ? moverAbajo() : moverArriba();
		setItemFocuseado(indiceFocos);
		event.stopPropagation();
		event.preventDefault();
	};
	const moverAbajo = (): number => {
		if (esElUltimoSeleccionado()) {
			setScrollActivado(false);
			return indiceItemFocuseado;
		}
		const nuevoIndice = indiceItemFocuseado + 1;
		setScrollActivado(nuevoIndice > 4);
		return nuevoIndice;
	};
	const moverArriba = (): number => {
		if (esElPrimerSeleccionado()) {
			setScrollActivado(false);
			return indiceItemFocuseado;
		}
		const nuevoIndice = indiceItemFocuseado - 1;
		setScrollActivado(nuevoIndice < listadoConSugerencias.length - 4);
		return nuevoIndice;
	};
	const esElUltimoSeleccionado = () => {
		const ultimoIndice = listadoConSugerencias.length - 1;
		return indiceItemFocuseado === ultimoIndice;
	};
	const esElPrimerSeleccionado = () => {
		const primerIndice = 0;
		return indiceItemFocuseado === primerIndice;
	};
	const handlerKeyDown = (event: any) => {
		switch (event.key) {
			case UP:
			case DOWN:
				flechasHandler(event);
				break;
			case ENTER:
				seleccionarHandler(listadoConSugerencias[indiceItemFocuseado]);
				break;
		}
	};
	const estaActivo = (index: number) => {
		return indiceItemFocuseado === index;
	};
	useEffect(() => {
		if (listadoConSugerencias.length === 0) {
			setMostrarSugerencias(false);
			setItemFocuseado(0);
		} else {
			setMostrarSugerencias(true);
			if (itemDeLaListaRef.current !== null && scrollActivado) {
				itemDeLaListaRef.current.scrollIntoView({
					behavior: 'smooth',
					block: 'start',
				});
			}
		}
	}, [
		listadoConSugerencias,
		indiceItemFocuseado,
		scrollActivado,
		mouseSobreDesplegable,
	]);

	useEffect(() => {
		if (!itemSeleccionado) {
			setValor('');
			setListarConSugerencias([]);
		}
	}, [itemSeleccionado]);

	return (
		<ContenedorInput
			id={id}
			onBlur={() => {
				if (!mouseSobreDesplegable) {
					setListarConSugerencias([]);
				}
			}}
		>
			<div>
				<span>{titulo} </span>
				<Tooltip textoPopUp={textoInformativo} />
			</div>
			<InputPersonalizado
				mostrarSugerencias={mostrarSugerencias}
				placeholder="..."
				type="text"
				onKeyDown={handlerKeyDown}
				onChange={onChangeInput}
				value={valor}
				data-cy={'input-cargar-habilidad'}
			/>
			<>
				{mostrarSugerencias && (
					<ListaDeSugerencias
						onMouseOut={() => {
							setMouseSobreDesplegable(false);
						}}
					>
						{listadoConSugerencias.map((e, index) => {
							return (
								<ItemLista
									key={uuidv4()}
									data-cy={`sugerencia-${e.nombre}`}
									id={e.id}
									estaActivo={estaActivo(index)}
									ref={
										indiceItemFocuseado === index
											? itemDeLaListaRef
											: null
									}
									onClick={event => {
										seleccionarHandler(listadoConSugerencias[index]);
										event.stopPropagation();
										event.preventDefault();
									}}
									onMouseOver={() => {
										setMouseSobreDesplegable(true);
										setItemFocuseado(index);
									}}
								>
									{e.nombre}
								</ItemLista>
							);
						})}
					</ListaDeSugerencias>
				)}
			</>
		</ContenedorInput>
	);
};

const ItemLista = styled.li<{
	estaActivo: boolean;
}>`
	margin-top: 6px;
	margin-bottom: 6px;
	${props =>
		props.estaActivo
			? `background-color: ${Colores.ROSA};`
			: `background-color: ${Colores.BLANCO_PURO};`};
`;

const ListaDeSugerencias = styled.ul`
	position: absolute;
	padding-left: 12px;
	padding-right: 12px;
	max-height: 137px;
	width: 411px;
	background-color: ${Colores.BLANCO_PURO};
	box-sizing: border-box;
	border: 1px solid ${Colores.VIOLETA};
	border-top: none;
	border-bottom-left-radius: 10px;
	border-bottom-right-radius: 10px;
	scrollbar-width: auto;
	scrollbar-color: ${Colores.VIOLETA};
	&::-webkit-scrollbar {
		width: 16px;
	}
	&::-webkit-scrollbar-thumb {
		background-color: ${Colores.VIOLETA};
		border-radius: 10px;
		border: 3px solid ${Colores.BLANCO_PURO};
	}
	overflow: auto;
	font-family: Dosis, sans-serif;
	font-size: 16px;
	outline: none;
	cursor: pointer;
	z-index: 1;
`;

const ContenedorInput = styled.div`
	position: relative;
	flex-direction: column;
	margin-right: ${Grilla.L};
`;

const InputPersonalizado = styled.input<{
	mostrarSugerencias: boolean;
}>`
	background-color: ${Colores.BLANCO_PURO};
	border: 1px solid ${Colores.VIOLETA} !important;
	border-radius: 10px;
	${props =>
		props.mostrarSugerencias
			? ` border-bottom-left-radius: 0px; border-bottom-right-radius: 0px;`
			: ` border-bottom-left-radius: 10px; border-bottom-right-radius: 10px;`};
	box-sizing: border-box;
	font-family: Dosis, sans-serif;
	font-size: 16px;
	height: 32px;
	margin-top: 6px;
	outline: none;
	padding-left: 12px;
	width: 411px;
`;

export default InputConSugerencias;
