import { TailSpin } from 'react-loader-spinner';
import styled from 'styled-components';
import { Colores } from '../constantes/style';

export const SpinnerBlanco = ({}) => {
	return (
		<ContenedorSpinner>
			<TailSpin
				color={Colores.BLANCO_PURO}
				visible={true}
				height={50}
				width={50}
			/>
		</ContenedorSpinner>
	);
};

const ContenedorSpinner = styled.div`
	position: absolute;
	top: 48%;
	left: 48%;
`;
