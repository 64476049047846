import styled from 'styled-components';
import Boton from './Boton';
import { useState } from 'react';
import IconoSalir from '../img/icono-menu.svg';
import { Colores } from '../constantes/style';

const imgsrc = IconoSalir;

interface HeaderProps {
	onCerrarSesion: () => void;
}

const MenuKebab = ({ onCerrarSesion }: HeaderProps) => {
	const [mostrarOpcionSalir, setMostrarOpcionSalir] = useState<boolean>(false);

	const desplegarOpcionSalir = (mostrarOpcionSalir: boolean) => {
		if (mostrarOpcionSalir) {
			return setMostrarOpcionSalir(false);
		} else {
			return setMostrarOpcionSalir(true);
		}
	};

	const cerrarSesion = () => {
		setMostrarOpcionSalir(false);
		onCerrarSesion();
	};

	return (
		<ContenedorMenu>
			<ContenedorIcono
				onClick={() => {
					desplegarOpcionSalir(mostrarOpcionSalir);
				}}
			>
				<Icono
					src={imgsrc}
					alt={'boton-menu-kebab'}
					data-cy={`boton-menu-kebab`}
				/>
			</ContenedorIcono>
			{mostrarOpcionSalir && (
				<ContenedoraBotonSalir>
					<Boton
						onSubmit={cerrarSesion}
						texto={'🏃Salir'}
						width={'80px'}
						color={Colores.VIOLETA}
						activColor={Colores.VIOLETA}
					></Boton>
				</ContenedoraBotonSalir>
			)}
		</ContenedorMenu>
	);
};

const Icono = styled.img`
	width: 5px;
	position: fixed;
`;

const ContenedorMenu = styled.div`
	display: flex;
	align-items: center;
	flex-direction: column;
	margin-left: 10px;
`;

const ContenedorIcono = styled.div`
	position: absolute;
	margin-top: 5px;
	width: 10px;
	&:hover,
	&:focus,
	&:active {
		cursor: pointer;
	}
`;

const ContenedoraBotonSalir = styled.div`
	position: absolute;
	background-color: ${Colores.BLANCO_PURO};
	height: 66px;
	width: 107px;
	margin-top: 45px;
	margin-right: 45px;
	border-radius: 14px;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	z-index: 1;
`;

export default MenuKebab;
